import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function SifcFaciliates() {
  // var settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      {" "}
      <div
        id="our_facilitation_spectrum"
        className="flex px-4 justify-center items-center flex-col bg-[#EFF5F0] py-8 md:py-[100px]"
      >
        <div className="container-width-SIFC-faclitates">
          <div className="headingInvestInPakistan md:!text-[40px] !text-[30px] mb-[18px] md:mb-[40px]">
            Our Facilitation Spectrum{" "}
          </div>
          {/* <div className="xl:hidden block">
            <Slider {...settings}>
              <div className="relative overflow-hidden w-full h-[588px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay">
                <div className="bg-cover bg-center w-full h-full bg-image3 relative ">
                  <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white opacity-100 transition-opacity duration-500 ease-in-out hover:opacity-0">
                    <div className="Sifc-faclitates-box-heading xl:!text-[30px] 2xl:!text-[36px]]">
                      Wholesome Approach{" "}
                    </div>
                  </div>
                  Hover content
                  <div className="content-hover absolute inset-0 flex flex-col px-[30px] pb-[48px] pt-[20px] items-end justify-end text-white opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                    <div>
                      <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                        Wholesome Approach
                      </div>
                      <p className="Sifc-faclitates-box-text">
                        Ensures policy-level interventions, prompt
                        decision-making, fast-track
                        implementation, and follow-up
                      </p>{" "}
                    </div>
                  </div>
                </div>
                <div className="overlay"></div>
              </div>
              <div className="relative overflow-hidden w-full h-[588px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay">
                <Link to="/about">
                  <div className="bg-cover bg-center w-full h-full bg-image2 relative ">
                    <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white opacity-100 transition-opacity duration-500 ease-in-out hover:opacity-0">
                      <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                        SIFC Sponsored Visa
                      </div>
                    </div>
                    <div className="content-hover absolute inset-0 flex flex-col px-[30px] pb-[48px] pt-[20px] items-end justify-end text-white opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                      <div>
                        <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                          SIFC Sponsored Visa{" "}
                        </div>
                        <p className="Sifc-faclitates-box-text">
                          Visa for investors and businessmen in 24 hours
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="overlay active"></div>
              </div>
              {/* <div className="relative overflow-hidden w-full h-[588px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay">
                <Link to="/sifcVisa">
                  <div className="bg-cover bg-center w-full h-full bg-image2 relative ">
                    <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white opacity-100 transition-opacity duration-500 ease-in-out hover:opacity-0">
                      <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                        SIFC Sponsored Visa
                      </div>
                    </div>
                    <div className="content-hover absolute inset-0 flex flex-col px-[30px] pb-[48px] pt-[20px] items-end justify-end text-white opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                      <div>
                        <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                          SIFC Sponsored Visa{" "}
                        </div>
                        <p className="Sifc-faclitates-box-text">
                          Visa for investors and businessmen in 24 hours
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="overlay"></div>
                </Link>
              </div> */}
          {/*<div className="relative overflow-hidden w-full h-[588px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay">
                <Link to="/about">
                  <div className="bg-cover bg-center w-full h-full bg-image1 relative ">
                    <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white opacity-100 transition-opacity duration-500 ease-in-out hover:opacity-0">
                      <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                        One-Stop Shop for Investment Facilitation
                      </div>
                    </div>
                    <div className="content-hover absolute inset-0 flex flex-col px-[30px] pb-[48px] pt-[20px] items-end justify-end text-white opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                      <div>
                        <div className="Sifc-faclitates-box-heading  xl:text-[27px] 2xl:text-[36px]]">
                          One-Stop Shop for Investment{" "}
                        </div>
                        <p className="Sifc-faclitates-box-text">
                          All stakeholders under one roof to facilitate and
                          handhold investors
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="overlay active"></div>
              </div>
            </Slider>
          </div> */}
          {/* <div className="xl:block hidden"> */}
          <div className="grid w-full grid-rows-3 md:grid-rows-1 md:grid-cols-3 gap-4">
            {/* <!-- Column 1 --> */}
            <div
              data-aos="zoom-in"
              className="relative overflow-hidden w-full h-[150px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay"
            >
              <Link to="/leadership_organization#organization">
                <div className="bg-cover bg-center w-full h-full bg-image3 relative ">
                  {/* Initial content */}
                  <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white md:opacity-100 opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-0">
                    <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                      Wholesome Approach{" "}
                    </div>
                  </div>
                  {/* Hover content */}
                  <div className="content-hover absolute inset-0 flex flex-col px-[30px]  md:pb-[48px] md:pt-[20px] justify-center  md:justify-end text-white md:opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                    <div>
                      <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                        Wholesome Approach
                      </div>
                      <p className="Sifc-faclitates-box-text">
                        Ensures policy-level interventions, prompt
                        decision-making, fast-track
                        implementation, and follow-up
                      </p>{" "}
                    </div>
                  </div>
                </div>
                <div className="overlay"></div>
                <div className="overlay2 md:hidden"></div>
              </Link>
            </div>
            <div
              data-aos="zoom-in"
              className="relative overflow-hidden w-full h-[150px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay"
            >
              <Link to="/sifcVisa">
                <div className="bg-cover bg-center w-full h-full bg-image2 relative ">
                  <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white md:opacity-100 opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-0">
                    <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                      SIFC Sponsored Visa
                    </div>
                  </div>
                  <div className="content-hover absolute inset-0 flex flex-col px-[30px]  md:pb-[48px] md:pt-[20px] justify-center  md:justify-end text-white md:opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                    <div>
                      <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                        SIFC Sponsored Visa{" "}
                      </div>
                      <p className="Sifc-faclitates-box-text">
                        Visa for investors and businessmen in 24 hours
                      </p>{" "}
                    </div>
                  </div>
                </div>
                <div className="overlay"></div>
                <div className="overlay2 md:hidden"></div>{" "}
              </Link>
            </div>
            <div
              data-aos="zoom-in"
              className="relative overflow-hidden w-full h-[150px] md:h-[588px] transition-all duration-500 ease-in-out hover-overlay"
            >
              <Link to="/about#mandate">
                <div className="bg-cover bg-center w-full h-full bg-image1 relative ">
                  <div className="content-initial absolute inset-0 top-[80%] px-[30px] text-white md:opacity-100 opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-0">
                    <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                      One-Stop Shop for Investment Facilitation
                    </div>
                  </div>
                  <div className="content-hover absolute inset-0 flex flex-col px-[30px]  md:pb-[48px] md:pt-[20px] justify-center  md:justify-end text-white md:opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
                    <div>
                      <div className="Sifc-faclitates-box-heading text-xl xl:text-[27px] 2xl:text-[36px]">
                        One-Stop Shop for Investment{" "}
                      </div>
                      <p className="Sifc-faclitates-box-text">
                        All stakeholders under one roof to facilitate and
                        handhold investors
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </Link>
              <div className="overlay"></div>
              <div className="overlay2 md:hidden"></div>{" "}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default SifcFaciliates;
