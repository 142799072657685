import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import "./WhyInvest.css";
import AOS from "aos";
import "aos/dist/aos.css";
export default function CoreValues() {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <>
      <div className="bgPictureInvestmentClimate">
        <div
          className="relative bg-cover bg-center h-[256px] md:h-[554px] overflow-hidden bg-white bgPictureInvestmentClimate"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading md:!text-[50px] !text-[35px] font-bold !text-center">
                  Our Core Values
                </h1>{" "}
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col py-8 md:py-24
      "
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !leading-10 !text-[30px] md:!text-[48px] mb-4">
            Environmental Stewardship <br></br>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className=" flex flex-row flex-wrap gap-4 justify-center px-4">
            <div className="py-[40px] WhyInvestBox  !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Harnessing Technology for Sustainable Energy
              </div>
              <div className="whyInvestPakistan !text-justify">
                Invest in a future where technology transforms waste into
                energy, propelling Pakistan to lead the charge in renewable
                energy by 2031. Our commitment to eco-friendly techniques for
                coal projects showcases a responsible approach to industrial
                progress.{" "}
              </div>
            </div>
            <div className=" py-4 md:py-[40px] WhyInvestBox !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Water Management Excellence
              </div>
              <div className="whyInvestPakistan !text-justify">
                Invest in a nation dedicated to cutting-edge water management
                systems, ensuring sustainability and resilience. Our commitment
                extends beyond efficiency contributing to a sustainability
                future.{" "}
              </div>
            </div>
            <div className=" py-4 md:py-[40px] WhyInvestBox !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Achieving Sustainable Development Goal 13
              </div>
              <div className="whyInvestPakistan !text-justify">
                Witness a steadfast commitment to environmental sustainability
                as we successfully achieve Sustainable Development Goal 13. By
                actively participating in carbon credit programs, we go beyond
                expectations to create a positive impact on our environment.
              </div>
            </div>
            <div className=" py-4 md:py-[40px] WhyInvestBox !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Living Indus Initiative - UN's Flagship Program
              </div>
              <div className="whyInvestPakistan !text-justify">
                Emphasizing community-led and transparent nature-based
                solutions, this program aims to restore over 30% of the Indus
                River Basin by 2030. We contribute to worldwide climate
                resilience by championing sustainable management, responsible
                water usage, and addressing climate change impacts.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="OurCoreValues flex justify-center items-center flex-col py-8 md:py-24">
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !leading-10 !text-[30px] md:!text-[48px] mb-4  !text-[#F08A23]">
            Fostering Socio-Economic Growth<br></br>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="flex flex-row flex-wrap gap-4 place-content-center px-4">
            <div className="  py-[40px] WhyInvestBoxSecond !h-full md:!h-[220px] !w-[496px] ">
              <div className="WP_text text-center !text-white">
                Job Creation and Social Impact
              </div>
              <div className="whyInvestPakistan !text-justify !text-white opacity-70">
                Invest with us to witness the creation of 6-8 million jobs,
                making a substantial impact on million of lives. Our projects
                not only boost economic growth but also create a socially
                responsible ecosystem.{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond !h-full md:!h-[220px] !w-[496px] ">
              <div className="WP_text text-center !text-white">
                Community-Centric Infrastructure
              </div>
              <div className="whyInvestPakistan !text-justify !text-white opacity-70">
                Explore opportunities in a community-centric environment, where
                enhanced infrastructure includes educational, medical, and
                technological institutions. Improved roads and IT connectivity
                ensure sustainable community development.{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond !h-full md:!h-[220px] !w-[586px] ">
              <div className="WP_text text-center !text-white">
                Empowering Through Human Resource Development
              </div>
              <div className="whyInvestPakistan !text-justify !text-white opacity-70">
                We are committed to an inclusive human resource development
                approach aimed at enhancing the employability of local
                communities through technical education and vocational training.
                We empower individuals with the hard and soft skill sets
                necessary for a dynamic job market, ensuring a
                capable workforce.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col py-8 md:py-24
      "
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !leading-10 !text-[30px] md:!text-[48px] mb-4">
            Upholding Ethical Standards<br></br>
            {/* <span className="WP_text text-center">
            </span> */}
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="flex flex-row flex-wrap gap-4 place-content-center px-4">
            <div className="  py-[40px] WhyInvestBox !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Secure Investment Environment
              </div>
              <div className="whyInvestPakistan !text-justify">
                Invest with confidence in a secure environment. Our commitment
                to investment protection and equal treatment for domestic and
                foreign investors establishes a level playing field, fostering a
                culture of trust.{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox !h-full md:!h-[220px] !w-[496px]">
              <div className="WP_text text-center">
                Gender Parity and Fair Processes
              </div>
              <div className="whyInvestPakistan !text-justify">
                Discover a nation dedicated to promoting gender parity and
                maintaining transparent, accountable, and fair processes. Our
                ethical standards ensure a business environment built on respect
                and integrity.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
