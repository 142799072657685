import React, { useState, useEffect } from "react";
import Footer from "../subcomponents/footer";
import NavBar from "../NavBar";
import FaqAccordian from "../subcomponents/FaqAccordian";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownFAQ from "../subcomponents/DropdownFAQ";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import "./Aboutus.css";
function FAQs() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const sector = searchParams.get("sector");
  const accordionData = {
    General: [
      {
        title:
          "Are there any focal persons or sub-office of SIFC in provinces?",
        content:
          "At present, SIFC is operating centrally from Prime Minister Secretariat, Islamabad and there is no regional sub-office.",
      },
      {
        title: "How can an investor approach SIFC for facilitation?",
        content:
          "Investors can contact SIFC through email (invest@sifc.gov.pk) with investment proposals, and subsequent engagements will be undertaken through various modes i.e., in-person meetings, online meetings, and email.",
      },
      {
        title: "Does SIFC facilitate both foreign and local investors?",
        content:
          "Yes, SIFC gives equal importance to investors from friendly countries and local investors.",
      },
      {
        title:
          "Does SIFC facilitate new investors or extend facilitation to existing investors as well?",
        content:
          "SIFC facilitates both new and existing investors to help resolve legacy issues.",
      },
      {
        title: "What is the private sector participation in SIFC initiatives?",
        content:
          "The private sector remains pivotal in all initiatives of SIFC, and relevant stakeholders are taken onboard during policy formulation.",
      },
      {
        title:
          "How can SIFC facilitate in the provision of new visas and their renewal?",
        content:
          "SIFC facilitates business and investor visas. Please see the SIFC Visa Page for further details.",
      },
      {
        title: "Why is SIFC facilitating investors in only four sectors?",
        content:
          "Initially, four sectors have been targeted to facilitate investments.",
      },
      {
        title:
          "Since some of the sectors are devolved to provinces in Pakistan, how will SIFC help in coordinating with provinces and other stakeholders?",
        content:
          "SIFC is an inclusive setup with representatives from relevant federal and provincial ministries and departments. Therefore, it provides a single platform for decision-making and implementation.",
      },
    ],
    ITTelecom: [
      {
        title:
          "How does SIFC contribute to encouraging investment in Pakistan's IT sector?",
        content:
          "SIFC, in collaboration with MOITT, plays a crucial role in attracting both local and foreign investment by offering incentives, advocating for favorable policies, supporting skill development, and actively promoting the export of software and IT services through PSEB.",
      },
      {
        title: "How can a startup get incubated in Incubation Center?",
        content:
          "Ignite's National Incubation Centers (NICs), overseen by industry experts, admit startups on a cohort basis. Applications are solicited periodically via the NICs' websites and dedicated social media channels. More details can be obtained at https://digiskills.pk/.",
      },
      {
        title:
          "What capacity building support is provided by Ignite to startups?",
        content:
          "Ignite provides human resource capacity building support through various different programs. Its flagship program ‘Digiskills’ has so far successfully graduated more than 3.5 million trainees in various verticals of ICT. More details about the program can be obtained at https://digiskills.pk/.",
      },
      {
        title: "What funding is provided by Ignite for R&D projects?",
        content:
          "Ignite provides funding for R&D projects through ‘Challenge Driven Innovation Fund’, ‘Technology Transition Fund’ and ‘NGIRI – National Grassroot ICT Research Initiative’. More details of these programs can be obtained at https://ignite.org.pk/.",
      },
      {
        title: "Who promotes the growth of Pakistan’s IT and ITeS sectors?",
        content:
          "The growth of Pakistan's IT and ITeS (Information Technology and Information Technology-enabled Services) sectors is promoted by the Pakistan Software Export Board (PSEB). PSEB is a government agency responsible for fostering and supporting the development of the IT industry in Pakistan. More details can be obtained at https://techdestination.com/",
      },
      {
        title:
          "What incentives or tax benefits are available to attract investments in the IT sector?",
        content:
          "Incentives for businesses in Pakistan's IT sector include up to 100% foreign ownership, profit repatriation, tax exemptions for venture capital entities, reduced income tax rates for PSEB-registered businesses, and a 100% tax credit for technology startups, along with the allowance for exporters to retain 50% of export remittances. For more detailed information, please refer to the 'Incentives in IT' section.",
      },
      {
        title:
          "What are the incentives provided by the Special Technology Zones Authority (STZA) for enterprises and zone developers, and for what duration are these incentives applicable?",
        content:
          "Investor incentives encompass tax exemptions (Income Tax Ordinance, 2001), import of capital goods, dividend income, capital gains, property tax, and sales tax. Features include a Special Foreign Currency Account and fully repatriable investment, profits, and dividends. More details can be obtained at https://www.stza.gov.pk/",
      },
      {
        title:
          "Are there opportunities for public-private partnerships in IT sector investments?",
        content:
          "Yes, opportunities for public-private partnerships in the IT sector exist in Software Technology Parks, Business Process Outsourcing Complexes, IT Parks, Software Development and Testing Centers, IT Consulting and Training Centers, Technology Incubators, Call Centers, and Data Centers.",
      },
    ],
    Agriculture: [
      {
        title: "Which entity of SIFC deals with land?",
        content:
          "Under the umbrella of SIFC, Green Corporate Initiative (GCI) Private Limited deals with land-related matters.",
      },
      {
        title:
          "What are the terms and conditions for investment in agriculture/land?",
        content:
          "Terms and conditions are as follows:\n" +
          "a. A minimum of 1,000 acres of land shall be offered to the investor.\n" +
          "b. Right of Use of Land will be given to the investor, not a lease.\n" +
          "c. Agreement Period will be 30 years: Initial term of 20 years, renewal for a further 10 years.\n" +
          "d. Mandatory use of Efficient Irrigation System.\n" +
          "e. Security Money: PKR 20 Million per 1,000 Acres.\n" +
          "f. Grace Period on Rent: Up to 3 years on undeveloped land.\n" +
          "g. Rent after the grace period: Based on the Business Model and Feasibility Study.\n" +
          "h. Subletting of land is not allowed.",
      },
      {
        title:
          "What procedure would be followed for investment in acquiring land for agriculture?",
        content:
          "The procedure is as follows:\n" +
          "a. Investor will approach SIFC through email address (invest@sifc.gov.pk or co-coordagri@sifc.gov.pk).\n" +
          "b. Investor will be asked to forward a letter of intent covering agriculture experience and financial details.\n" +
          "c. After initial engagement at SIFC, investor will be referred to GCI where a patch of land will be offered from the available portion.\n" +
          "d. On satisfaction of the investor, MoU will be signed with GCI.\n" +
          "e. Investor will be directed to the land site for a physical visit.\n" +
          "f. In case of satisfaction after a physical visit, the investor will be asked to submit a feasibility study and a business plan.\n" +
          "g. Feasibility study and business plan will be evaluated by GCI. In case of acceptance, a project execution agreement will be signed between investor and GCI.\n" +
          "h. Simultaneously, security money of PKR 20 Million per 1,000 acres will be submitted by investors through a Pay Order.\n" +
          "i. Thereafter, the land will be handed over to the investor for the agreed-upon period.",
      },
      {
        title:
          "Can applications from expatriate Pakistanis or any Pakistani away from major cities be considered online?",
        content: "Yes. Investors can approach through the email address.",
      },
      {
        title:
          "Is there any developed land (cropped land) available for offering to investors?",
        content: "Mostly undeveloped land is available.",
      },
      {
        title:
          "Any map details available showing exact areas of land available for investors?",
        content:
          "GCI has the marked map available with them. The same will be shared once MoU has been signed.",
      },
      {
        title:
          "How much land is available (location-wise) with SIFC (GCI) for investors in Punjab, Sindh, KPK, and Baluchistan?",
        content:
          "At present, land is readily available in Cholistan (Punjab), and the process of acquiring land in other provinces is under process.",
      },
      {
        title:
          "Land selection is as per SIFC (GCI) Choice or Investor is free to select from available Land?",
        content:
          "Limited choices will be available in the shape of a few blocks of available land, and the same will be offered to investors for selection.",
      },
      {
        title:
          "Will SIFC (GCI) provide a pre-feasibility study of the project as per the soil profile, weather, etc., of available land?",
        content:
          "On request, Investors will be referred to LIMS section of GCI where technical data will be shared of available land. Moreover, investors will be requested to prepare their feasibility study for satisfaction.",
      },
      {
        title:
          "Separate Company establishment is mandatory for this scheme, or any already registered Company / Partnership can enter JV with GCI.",
        content:
          "It should be a company, whether new or already established, but related to Agriculture for JV.",
      },
      {
        title: "What is the Agri Mart Concept under GCI?",
        content:
          "A location where the provision of maximum quality items related to Agriculture is available to the investors at the doorstep under one roof.",
      },
      {
        title:
          "What is the ownership status of fixed/movable assets excluding agricultural produce purchased by the Investor for the cultivation of land after the end of tenure i.e., 20 + 10 years?",
        content:
          "Disposal of movable assets depends upon the Investor, whereas the value of fixed assets may be finalized in negotiations, but the first right of refusal will be with GCI.",
      },
      {
        title:
          "What benefits/incentives are there for domestic and foreign investors?",
        content:
          "Incentives are for both domestic and foreign investors, which include a grace period of 3 years, provision of technical support to investors about land through the LIMS center, availability of Agri Mart, provision of any subsidies announced by the government regarding agriculture.",
      },
      {
        title:
          "What is the process of Renewal of the contract after the end of tenure i.e. 20 + 10 years? Will the 1st owner be given priority?",
        content:
          "On termination of the contract, the land will be opened for a contract to all investors. No policy regarding priority to the 1st investor exists.",
      },
      {
        title: "How many models of investment exist in agri land?",
        content:
          "Mainly 4 x models are available:\n" +
          "1. Registered JV model\n" +
          "2. Unregistered JV model\n" +
          "3. Contractor Model\n" +
          "4. Investor Model",
      },
      {
        title: "What is the registered JV model?",
        content:
          "In the registered JV model, GCI and the investor enter into an agreement by establishing SPV on an equity basis which will be registered in SECP.",
      },
      {
        title: "What is the unregistered JV model?",
        content:
          "In the Unregistered model, GCI and investors (small and medium size) enter into a JV on an equity basis.",
      },
      {
        title: "What is the contractor model?",
        content:
          "Contract will be signed with investors by GCI as a procedure in vogue in the country. The amount, whether in cash or in kind, will differ from place to place and will be finalized during negotiation while finalizing the agreement.",
      },
      {
        title: "What is the investor model?",
        content:
          "Investor will provide the finances and GCI will execute the operations. Share will be decided in negotiations.",
      },
      {
        title: "How is the rent of land decided?",
        content:
          "It depends upon the condition of the land and will be finalized during the negotiations. At present, for Cholistan, the following rent policy is finalized whether in cash or in kind:\n" +
          "1. 1st 3 years considered as a grace period\n" +
          "2. Equal to 14 maunds yield of wheat/acre/year from 4th till 7th year\n" +
          "3. Equal to 16 maunds yield of wheat/acre/year from 8th till 11th year\n" +
          "4. Equal to 18 maunds yield of wheat/acre/year from 12th till 15th year\n" +
          "5. Equal to 21 maunds yield of wheat/acre/year from 16th till 19th year\n" +
          "6. Review on renewal of the contract.",
      },
      {
        title:
          "What type of infrastructure and utility services are available in the area where land is being offered?",
        content:
          "Requisite infrastructure is being developed in Cholistan to ensure accessibility and provision of basic utility services (water, electricity, and road access) at the earliest.",
      },
      {
        title:
          "What is the source of water available on the land in Cholistan?",
        content:
          "As per the hydrological survey, approximately 30% of the land possesses underground sweet water.",
      },
      {
        title: "What is the alternate source of sweet water in Cholistan?",
        content:
          "GCI is planning to bring underground sweet water from the beds of Indus and Sutlej Rivers through water channels/conduit/pipeline at the earliest. Moreover, if the hydrological survey permits, sustainable extraction of sweet water from the nearest available source can be transported through a pipeline to the desired location by investors.",
      },
      {
        title:
          "If that water lies on the land of other owners, who will give authority for the right of way of boring and transportation?",
        content:
          "As per GCI policy, the authority of construction of tube wells on the land of other owners (within delineated land under GCI), the scale of extraction of water, and its distribution lie with GCI.",
      },
      {
        title:
          "What would be the financial model for the provision of water to the investors?",
        content:
          "GCI is formulating its financial model for monitoring, metering, and provision of water which is dependent upon future water pipeline projects.",
      },
      {
        title: "Is there any instruction for the use of water?",
        content:
          "As per policy, High Efficiency Irrigation System will be used and flood irrigation is forbidden. However, flooding may be allowed only during the initial phase of the preparation of land.",
      },
      {
        title:
          "Will agriculture tax be implemented on the development of land offered by GCI?",
        content:
          "All types of taxes will be applicable as per the law of the land.",
      },
      {
        title: "Will subsidized urea be available for the investors?",
        content: "Urea will be available at controlled rates.",
      },
      {
        title:
          "To develop the land modern equipment and machinery are required. Can SIFC (GCI) assist in providing it?",
        content:
          "As per GCI policy, High Efficiency Irrigation System including the pivot system, drip irrigation system, etc., will be adopted by investors to get maximum benefit. SIFC (GCI) can assist in purchasing such machinery.",
      },
      {
        title:
          "Is there any restriction to dedicate a portion of the allotted land for infrastructure development necessary to support agriculture activities?",
        content: "There is no restriction.",
      },
      {
        title:
          "What type of seed varieties are recommended for sowing in Cholistan?",
        content:
          "Varieties that are heat-tolerant and moisture stress are recommended for sowing.",
      },
      {
        title: "How to obtain quality seed from a registered seed company?",
        content:
          "List of Registered Seed companies are available on FSC&RD official Website: https://www.federalseed.gov.pk/",
      },
      {
        title: "What is GCI?",
        content:
          "GCI stands for Green Corporate Initiative Private Limited under the umbrella of SIFC. GCI is mandated to improve agriculture in Pakistan and ensure food security. LIMS (Land Information and Management System) forms part of GCI, which provides state-of-the-art agri-tech solutions to boost the agriculture sector. Further information can be obtained from the website: http://www.limspakistan.com/",
      },
      {
        title:
          "Who is responsible for reporting agricultural data, and to whom is it reported?",
        content:
          "LIMS, under GCI, is the primary custodian of the data and the recipients and beneficiaries of the data are; Agricultural agencies, farmers, private companies etc. Reports are regularly shared with the government bodies, research institutions, and stakeholders to serve the primary purpose of impacting the agriculture.",
      },
      {
        title: "Who can access this data and generate reports?",
        content:
          "LIMS has granted access to all the four provinces, AJK, and GB’s agriculture departments. The private companies and small farmers, who are interested in knowing information pertaining to their farms or any specific information, can get this data and reports.",
      },
    ],
    Energy: [
      {
        title: "How to bring proposals for the oil and gas sector to SIFC?",
        content:
          "Forward your proposal to invest@sifc.gov.pk with a copy to co-coordenergy@sifc.gov.pk.",
      },
      {
        title:
          "How will I be assisted/contacted by SIFC after I forward my proposal to SIFC?",
        content:
          "SIFC energy section will establish contact with you to seek further details and understand your proposal for more clarity.",
      },
      {
        title:
          "How can I get information on related procedures for starting a business in the oil & gas sector of Pakistan?",
        content:
          "Information on related procedures is available at https://ogra.org.pk/faqs-2. In case of any other queries, SIFC energy section will provide help in getting the required clarity.",
      },
      {
        title:
          "Is there any policy existing for investment in Green Hydrogen Production projects?",
        content:
          "With the effort of SIFC, NEECA is launching a pre-feasibility study for Green Hydrogen. For more details, visit https://neeca.gov.pk.",
      },
      {
        title: "What is the Potential of RE in Pakistan?",
        content: {
          a: "Hydro Power – 64,000 MW",
          b: "Wind Power – 346,000 MW",
          c: "Solar Power – 2.9 Mn MW",
          d: "Biomass / Waste-To-Energy – 20,709 MW",
        },
      },
      {
        title: "How RE potential of Pakistan is being utilized?",
        content: {
          a: "Hydro Power – 11,000 MW out of 64,000 MW",
          b: "Wind Power – 1845 MW out of 346,000 MW",
          c: "Solar Power – 530 MW out of 2.9 Mn MW",
          d: "Biomass / Waste-To-Energy – 259.1 MW out of 20,709 MW",
        },
      },
      {
        title: "Is there any Hydro IPP in Pakistan?",
        content:
          "84 MW New Bong is the 1st Hydro-IPP in Pakistan/AJK. 147 MW Patrind Hydropower Project, 102 MW Gulpur Hydropower Project 720 MW Karot Hydropower project.",
      },
      {
        title: "What is the main role of PPIB wrt pte investments in Pakistan?",
        content:
          "Private Power and Infrastructure Board (PPIB) is a “One-Window Facilitator” on behalf of the Government of Pakistan (GoP) to promote private investments in the power sector. PPIB approves IPPs (Independent Power Producers), issues LOIs & LOSs (including Tripartite LOSs), approves Feasibility Studies, executes Implementation Agreements (IAs) and provides guarantees on behalf of Government of Pakistan.",
      },
      {
        title: "How many IPPs supported by PPIB are functional in Pakistan?",
        content: {
          a: "Conventional Fuel & Hydro Plants - 46 x Projects (22,174 MW)",
          b: "Wind Power Projects – 36 x Projects (1845.475 MW)",
          c: "Solar Power Projects – 7 x Projects (530 MW)",
          d: "Biomass / Waste-To-Energy – 8 x Companies (259.1 MW)",
        },
      },
      {
        title:
          "What is the min equity requirement to finance IPPs in Pakistan?",
        content:
          "The minimum equity requirement for an IPP in Pakistan is 20% of the total project cost.",
      },
      {
        title: "Who determines the Tariff for IPPs?",
        content:
          "National Electric Power Regulatory Authority (NEPRA) determines the tariff for the IPPs.",
      },
      {
        title: "Does PPIB facilitate Power projects in Private sec only?",
        content:
          "PPIB facilitates Power Project in Private Sector. However, the Government of Pakistan encourages the establishment of Power Projects in Public-Private Partnership in line with the scope of Power Policy-2015.",
      },
      {
        title:
          "What is the role of Provinces/ AJK in development of power Projects?",
        content:
          "The major role of Provinces/AJK is the facilitation in the development of hydropower project, facilitation in the acquisition of land, payment of the compensation of land and environmental mitigation, issuance of environmental NOC, provision of security as well as signing of Water Use Agreement (AJK Implementation Agreement in case the project is in AJ&K territory).",
      },
      {
        title: "Which PPIB Power Policy is in Vogue?",
        content:
          "Currently, National Electricity Policy-2021 to be read in conjunction with Power Policy -2015.",
      },
      {
        title: "What is IGCEP and its objectives?",
        content:
          "IGCEP stands for Indicative Generation Capacity Expansion Plan. The key objective of the IGCEP is to develop a low-cost, 10-year indicative generation expansion plan to meet load and energy demand in a reliable and sustainable manner, while maximizing the use of indigenous resources for energy security. IGCEP is approved by NEPRA after conducting a formal public hearing and seeking comments from all stakeholders. IGCEP is updated annually.",
      },
      {
        title:
          "What are the main project agreements and by whom these are signed?",
        content:
          "Implementation Agreement (IA) is executed between PPIB on behalf of GoP and the Project Company Power Purchase Agreement (PPA) is executed between Central Power Purchasing Agency (CPPA-G) and the Company/IPP Coal/Fuel/Gas Supply Agreement(s) between the Company / IPP and the Coal/Fuel/Gas Supplier(s) Water Use Agreement is executed between the provincial government and the project company.",
      },
      {
        title: "What is the Performance guarantee?",
        content:
          "An unconditional, irrevocable, on-demand bank guarantee provided by the Company in favor of GOP (PPIB).",
      },
      {
        title: "What is a tripartite letter of support?",
        content:
          "It is actually a Letter of Support executed between three parties/entities (PPIB, Provincial Government and the project company) in order to achieve Financial Closing and other project milestones (including signing of IA and PPA) in developing the project.",
      },
    ],
    Industry: [
      {
        title: "What does PIDC do?",
        content:
          "Pakistan Industrial Development Corporation (Pvt.) Ltd. (PIDC) has a role of facilitator of industrial development which is currently focused on the development of Industrial Parks / Special Economic Zones as the only Federal Government Developer of SEZs in the country.",
      },
      {
        title: "What is a SEZ?",
        content:
          "A Special Economic Zone is a blanket term for various types of industrial/export processing/trading zones with specific types of enterprises operating in a well-defined geographic area where certain economic activities are promoted by a set of policy measures that are not generally applicable to the rest of the country.",
      },
      {
        title:
          "What makes a SEZ different from other Industrial Park/Industrial Estate?",
        content:
          "The SEZ Act provides a comprehensive legal framework to regulate the SEZs, where each individual stakeholder is mandated by law to discharge their respective responsibilities to develop a successful SEZ.",
      },
      {
        title: "What are fiscal incentives allowed for a SEZ?",
        content:
          "SEZ is entitled to one-time exemption from customs duties and taxes on import of plant and machinery & ten-year income tax exemption.",
      },
      {
        title: "How to apply for a plot in a PIDC SEZ?",
        content:
          "Please provide your contact information and submit a brief requirement at (info@pidc.com.pk), and PIDC representative will get in touch with you to explain the process.",
      },
      {
        title:
          "Who is eligible to apply for plot in the Special Economic Zones (SEZs)?",
        content:
          "Only companies registered with Securities & Exchange Commission of Pakistan – SECP are eligible to apply for an industrial plot in an SEZ.",
      },
      {
        title: "How much application processing fee does PIDC charge?",
        content:
          "Application processing fee of Pak Rupees 1,000,000/- (PKR 1 million) is payable by the Applicant at the time of submitting the Application – refundable in case if not approved and adjusted in the first installment if approved.",
      },
      {
        title: "What utilities are available at PIDC SEZs?",
        content: "Electricity, gas, water, sewerage & garbage disposal.",
      },
      {
        title: "How does PIDC maintain SEZs?",
        content:
          "PIDC charges an annual maintenance fee to allottees to cover the expense of regular upkeep and maintenance of SEZ’s infrastructure.",
      },
      {
        title: "When possession of plot is given in a SEZ?",
        content:
          "Possession of Plot is only given after receipt of payment of 1st installment of the price of the plot.",
      },
      {
        title: "When can construction start on a plot?",
        content:
          "It is mandatory to commence construction within six months from the possession of the plot.",
      },
      {
        title: "What is the building construction period?",
        content:
          "The allottee must complete the project within 24 months and commence commercial production.",
      },
      {
        title:
          "When can the title of SEZ plot be registered in the allottee’s name?",
        content:
          "It can only be registered once the approved project is established and at least six months of commercial operation has been completed.",
      },
      {
        title:
          "What happens in the case of delay in commencement/completion of construction?",
        content:
          "The allotment/possession of the plot is liable for cancellation/repossession if the allottee fails to meet the stipulated timeline.",
      },
      {
        title: "Is a SEZ plot transferable?",
        content:
          "Plot in a SEZ is non-transferable and can only be surrendered back to the developer i.e. PIDC.",
      },
      {
        title:
          "Do I need a license for manufacturing vehicles in Pakistan and what is the process for this?",
        content:
          "Engineering Development Board is authorized to issue the manufacturing certificate. Manufacturers can apply to EDB for verification of in-house facilities on the prescribed format for verification.",
      },
      {
        title:
          "What are incentives allowed for the manufacture of automobiles in Pakistan?",
        content:
          "The vendor can avail the concessionary rate of customs duty on the import of raw material, sub-component, components, and assemblies if not being manufactured locally. The localized auto parts are protected through additional customs duty.",
      },
      {
        title: "What are incentives allowed for Electric Vehicles?",
        content:
          "The OEMs can also avail the concessionary rate of customs duty on the import of EV specific parts @ 1%, Sales tax is at 1%. Import of EV chargers also attracts 1% CD, 0% ACD, however for charging infrastructure, regulations are being enforced by NEECA.",
      },
      {
        title:
          "What are available incentives for the manufacturing of Mobile Devices locally?",
        content:
          "Manufacturers can avail a concessionary rate of duty on the import of parts/ components of mobile in SKD/CKD. Approximately 20% tariff protection has been provided to CKD assembly as opposed to CBU imports.",
      },
      {
        title: "What is the List of Locally Manufactured Goods?",
        content:
          "The list of locally manufactured goods is a reference document to protect the local industry against concessionary imports. Engineering, industrial, consumer other than agriculture and textile product can be applied to enlist in the list of locally manufactured goods.",
      },
      {
        title:
          "What is the procedure of registering a product in the list of locally manufactured goods?",
        content:
          "Procedure has been adopted for updating the CGO is as follows:\na. Existing CGO list is available on EDB website.\nb. All stakeholders, associations, chambers, and industries are approached through letters, emails, and advertisement in the newspaper.",
      },
      {
        title:
          "What is the procedure for availing the incentives under Export Facilitation Schemes (EFS)?",
        content:
          "Following is the procedure being adopted by Engineering Development Board:\na. EDB collects relevant information from the firm.\nb. Technical evaluation of information / Desk audit.\nc. EDB’s Technical Team physically verifies the manufacturing facility of the manufacturer and determines IOR & wastages.",
      },
      {
        title: "Does EDB charge for the services it provides?",
        content: "Currently, EDB does not claim any charges on any activity.",
      },
    ],
    Mines: [
      {
        title:
          "What are the key areas of opportunity in exploration and mining in the country?",
        content:
          "Pakistan’s mining sector has strong potential for long term sustainable development. The country has a vast outcrop area (66% of the total area) with great geological potential for the discovery of sizeable mineral deposits. Due to tectono-geological formations, the country possesses diversified mineral resources, ranging from clays, building stones, industrial raw materials, fossil fuels to world-class metallic ores and precious gemstones.",
      },
      {
        title:
          "What are some recent success stories of exploration in Pakistan?",
        content:
          "Details on the most recent success stories of mineral exploration are as under:\n" +
          "• Reko Diq Copper & Gold in Chagai District of Baluchistan.\n" +
          "• Barite-Lead-Zinc, Lasbela District of Baluchistan.\n" +
          "• Copper deposits in Muhammad Khel, Waziristan, Khyber Pakhtunkhwa.\n" +
          "• Thar Coalfield, Tharparkar, Sindh.",
      },
      {
        title:
          "How can an investor access data from the Geological Survey of Pakistan?",
        content:
          "The data can directly be obtained from Geological Survey of Pakistan having Headquarters in Quetta and regional offices in Islamabad and Provincial capitals. Further information can be found on the GSP’s website: https://gsp.gov.pk/",
      },
      {
        title: "What is the regulatory framework for mining in Pakistan?",
        content:
          "The exploration and development of minerals (except oil, natural gas, and nuclear substances) are under the regulatory authority of the respective Province. In each of the provinces, a separate Department of Mines & Minerals is established. The Departments are mandated to map resources, grant, renew & cancel mineral titles, regulate mining and development operations, occupational health, safety & environment (HSE) matters. Work on harmonization of the mineral framework (legal and regulatory) at the National level is being steered through SIFC to further streamline the processes.",
      },
      {
        title:
          "What is the process to establish the legal entity to operate in the mineral sector of Pakistan?",
        content:
          "Establishment of a mining entity can be registered as sole proprietorship, partnership, or a company with the Securities and Exchange Commission of Pakistan (SECP). Registration details can be obtained by visiting the commission website: https://www.secp.gov.pk/. The entity must be registered with the Federal Board of Revenue for tax purposes. After incorporation, the entity is eligible to apply for a mineral title.",
      },
      {
        title:
          "What are the various types of licenses under the mining concession rules?",
        content:
          "Mining Concession Rules of each Province/Federating Units provide the following mineral titles for large-scale mining projects:\n" +
          "- Reconnaissance License\n" +
          "- Exploration License\n" +
          "- Mineral Deposit Retention License\n" +
          "- Mining Lease",
      },
      {
        title:
          "What is the application process for an exploration / exploitation license?",
        content:
          "The principle of first-come-first-served is generally followed for the grant of concessions. For the purpose, an application on the prescribed form available with all provincial Mines & Minerals Development Departments can be submitted for the area of interest. Mineral titles having proven reserves are awarded through a competitive bidding process duly publicized in the national or international media.",
      },
      {
        title: "What is the process of obtaining environment/forest approval?",
        content:
          "An application along with Environmental Impact Assessment (EIA) report and necessary documents for the proposed mining activity needs to be submitted to the Provincial Environment Department and Forest/Wildlife Department. The Departments evaluate the potential environmental and social impacts of the project in due course of time and grant necessary approval subject to the fulfillment of laid-down criteria.",
      },
      {
        title: "What areas are protected?",
        content:
          "Generally, certain types of areas are protected from mining activities in the country which include:\n" +
          "- Forest Reserves\n" +
          "- Cultural, archaeological & heritage sites\n" +
          "- Residential and urban areas",
      },
      {
        title: "What is the taxation mechanism in Pakistan?",
        content:
          "Mining sector is subjected to normal tax mechanism. Generally, the following taxes are applicable to the industry:\n" +
          "1. Income Tax (Corporate Tax, Minimum Corporate Tax)\n" +
          "2. Withholding Tax (Dividends, Non-Resident Contractor)\n" +
          "3. Other Taxes (Sales Tax, Additional Profit Tax)\n" +
          "4. Other Levies (Zakat for Muslims, Workers’ Profit Participation Fund, Workers’ Welfare Fund, Employees’ Old Age Benefits)",
      },
      {
        title: "What other payments are payable by license holders?",
        content:
          "Provincial Departments generally collect the following types of receipts from the mineral sector:\n" +
          "1. Title Application Fee;\n" +
          "2. Retention Fee;\n" +
          "3. Demarcation Fee;\n" +
          "4. Annual Rent;\n" +
          "5. Mining Lease Fee;\n" +
          "6. Royalty;\n" +
          "7. Renewal Fee.\n" +
          "8. Employees Social Security Contribution.\n" +
          "9. Excise Duty; and\n" +
          "10. Workers’ Children Cess, etc.",
      },
      {
        title: "Is there any fiscal incentive in the mineral sector?",
        content:
          "Under the Government’s schemes, lucrative incentives are available to the mining companies. Further information can be obtained from our Investment Climate page.",
      },
      {
        title: "How much time is required for the grant of mineral titles?",
        content:
          "Generally, it takes 2 x months, provided the application process is complete in all respects.",
      },
      {
        title: "Can foreign companies obtain the mineral title?",
        content:
          "Foreign companies are free to apply; however, the mineral title will only be given until the foreign company is incorporated locally.",
      },
      {
        title: "How can SIFC facilitate the investor in the mineral sector?",
        content:
          "SIFC has established a mechanism of Mineral Sectoral Group comprising relevant representatives from federal and provincial ministries and departments and through this platform various issues are discussed and facilitative measures are implemented through consensus.",
      },
    ],
    investmentClimateFaqData: [
      {
        title: "How is protection given to investors from foreign countries?",
        content:
          "Existing legislation provides sufficient protection to foreign investments as enshrined in Foreign Private Investment (Promotion & Protection) Act-1976, Protection of Economic Reforms Act-1992, and Foreign Investment (Promotion and Protection) Act-2022.",
      },
      {
        title: "What is the trend of opening new businesses in Pakistan?",
        content:
          "With improved confidence, more investors are tapping into Pakistan’s market. The Securities and Exchange Commission of Pakistan (SECP) registered 27,746 new companies in the FY 2022-23 showing an increase of around 5 percent as compared to the previous fiscal year. The total number of registered companies now stands at 196,805.",
      },
      {
        title:
          "What is the domestic dispute resolution mechanism in Pakistan with regard to investment?",
        content:
          "Pakistan has recently put in place Investment Ombudsman to ensure speedy resolution of investment-related issues. In addition, Telecom Tribunal has also been recently introduced to facilitate investors in the telecom sector.",
      },
      {
        title: "Does your country allow recourse to international arbitration?",
        content:
          "Yes, after exhausting the local options of dispute resolution, investors have options to recourse to international arbitration.",
      },
      {
        title:
          "What internationally recognized mediation centers/agencies operate in Pakistan?",
        content:
          "Number of centers are operating in Pakistan including:\n\nPublic\n• Trade Dispute Resolution Organization (TDRO).\n• Karachi Centre for Dispute Resolution (KCDR).\n• 3 x Mediation Centers established in Lahore.\n• SAARC Arbitration Council.\n\nPrivate\n• International Dispute Resolution Institute.\n• International Center for Appropriate Dispute Resolution and Prevention.\n• Musaliha International Center for Arbitration and Dispute Resolution.\n• Centre for International Investment and Commercial Arbitration (CIICA).",
      },
      {
        title:
          "What forms of corporate organization can be established in Pakistan?",
        content:
          "There are three corporate organization forms registered by the SECP:\n1. Partnership Act 1932\n2. Limited Liability Partnership, which is registered under the Limited Liability Partnership Act-2017.\n3. Company, which is registered under the Companies Act, 2017.",
      },
      {
        title:
          "Under Companies Act-2017, what types of companies can be established?",
        content:
          "Following companies can be established:\n1. Company limited by shares (Single member company, Private company, Public company).\n2. Company limited by guarantee (With share capital and Without share capital).\n3. Unlimited company.",
      },
      {
        title: "Can a foreigner own land for business purposes?",
        content:
          "Yes, foreigners can own land after the incorporation of a company in SECP. Acquisition of land will be governed by provincial and federal laws, as applicable.",
      },
      {
        title: "Do you have any limitations on foreign investment entry?",
        content:
          "100% foreign ownership is allowed except in specific sectors including airline, banking, engineering, agriculture, and media.",
      },
      {
        title:
          "Is there any restriction on repatriation of profits, dividends, or any other funds?",
        content:
          "Foreign investors, at any time, can repatriate profits, dividends, or any other funds in the currency of the country from which the investment originated.",
      },
      {
        title: "Are there special incentives for investments in SEZs?",
        content:
          "Yes, a set of incentives (special and general) are offered in SEZs. For more details, please see our investment climate page (incentives section).",
      },
      {
        title: "Can foreign investors access local credit and finance?",
        content:
          "Foreign investors in all sectors are allowed to access domestic borrowing subject to prevailing rules/regulations of SECP and SBP and observance of the Debt-Equity ratio.",
      },
      {
        title:
          "Is there a regime in Pakistan for the protection of industrial and intellectual property rights (e.g., patents, trademarks)?",
        content:
          "Yes, there is a well-established regime for the registration, protection, and enforcement of intellectual property rights in Pakistan. It is being administered under one umbrella organization i.e. Intellectual Property Organization (IPO-Pakistan). There is effective coordination between IPO-Pakistan and law enforcement agencies to address issues of piracy and counterfeiting in the country. Specialized IP Tribunals are functional (Karachi, Lahore, and Islamabad) to make timely decisions on IP-related cases. For further details please visit www.ipo.gov.pk.",
      },
      {
        title:
          "Do you have specific environmental limitations on industrial production (disposal of waste, pollution controls, etc.)?",
        content:
          "Yes, ISO 1400 (2014) is applicable in Pakistan, which monitors industrial activities besides, enactment of Environmental Protection Act-1997 across the country. Establishment of Environmental Tribunals (one in each province) to deal with environmental cases.",
      },
      {
        title:
          "What International Consultancy/Accounting Firms are operating in Pakistan?",
        content:
          "There are many (around 28) Accounting Firms that are registered with ICAP and have foreign affiliations some of them are as under:\n\n1. A.F. Ferguson (PwC) & Co.\n2. KPMG Taseer Hadi & Co. Chartered Accountants.\n3. Ernst & Young Ford Rhodes Sidat Hyder & Co.\n4. Deloitte Yousuf Adil & Co. Chartered Accountants.\n5. Baker Tilly Mehmood Idrees Qaman\n6. RSM Avais Hyder Liaquat Nauman\n7. PKF F.R.A.N.T.S\n8. Rahman Sarfaraz Rahim Iqbal Rafiq\n9. Riaz Ahmed & Co.\n10. Grant Thornton Anjum Rahman.\n11. Mckenzie & company.\n12. PwC.",
      },
      {
        title:
          "What is the state of the local supplier base (availability of raw materials, design and engineering services, machine maintenance and repair, printing, marketing, advertising, etc.) and the local supplier network system?",
        content:
          "The local supplier base is easily available in Pakistan to cater to the needs of various industries.",
      },
      {
        title: "Do you have a stock exchange/equity market?",
        content:
          "Yes, The Pakistan Stock Exchange (PSX) is the stock exchange of Pakistan with trading floors in Karachi, Islamabad, and Lahore.",
      },
      {
        title:
          "Do you have any seaports? If so, do they handle container cargoes? Do you have an Export Processing Zone?",
        content:
          "Pakistan has three major seaports named as Karachi Port, Muhammad Bin Qasim port, and Gwadar port (deep-sea port). All big ports handle cargoes.",
      },
      {
        title: "What are the incentives of EPZs in Pakistan?",
        content:
          "Pakistan offers incentives in EPZs. For more details, please visit https://epza.gov.pk/faq/",
      },
      {
        title: "What is the state of utility services for Industries?",
        content:
          "Industries are given due focus for the provision of utilities including electricity, gas (natural, LNG), water, and telecommunication.",
      },
    ],
    tourism: [
      {
        title:
          "Do you have a developed road network and inland transportation facilities?",
        content:
          "Yes, Pakistan has developed an efficient and well-integrated network of road and rail infrastructure to support business activities. The transportation ecosystem also provides opportunities to connect to regional countries for trade.",
      },
      {
        title: "Tourism Attractions in KP",
        content:
          "KP Province boasts diverse attractions, including historic sites, natural landscapes, and cultural experiences in Malakand and Hazara Division. SWAT VALLEY: Often referred to as the 'Switzerland of Pakistan,' having famous tourist spots like Kalam, Gabeen Jebba, Miandam, Kundal Lake and Mahudand Lake, are among many other popular attractions Swat Valley is known for its lush green valleys, snow-capped mountains, and clear rivers. Malam Jabba, a famous ski resort, is located in this region. PESHAWAR: The provincial capital, Peshawar, has a rich history and is known for its cultural heritage, vibrant bazaars, historical landmarks, and diverse culture. The Qissa Khwani Bazaar, Peshawar Museum, Masjid Muhabbat Khan, Sehti’s Street, Gor Ghatri and Clock Tower are among many other popular attractions. A historically significant mountain pass, the Khyber Pass has been a vital trade route for centuries. It is known for its strategic importance and historical forts CHITRAL: Located in the northern part of KP having Kalash as one of the oldest living Pagan tribe. Chitral is also famous for its scenic beauty, including the Chitral Valley and the Tirich Mir, which is the highest mountain in the Hindu Kush range.",
      },
      {
        title: "Safety Concerns for Tourists in KP Province",
        content:
          "The KP province is a safe area for tourists traveling to the most popular tourism destinations. The Government has restricted some areas where tourists need to obtain NOC before visiting.",
      },
      {
        title: "Best Time to Visit KP Province",
        content:
          "The best time to visit KP is summer season mainly from June to September during this ideal period one can enjoy spring and bloom season as well as pleasant weather and the golden Autumn in high mountains.",
      },
      {
        title: "Explore the Culture of KP Province",
        content:
          "Exploring the culture of Khyber Pakhtunkhwa can be a rich and rewarding experience as the province is known for its diverse culture, traditions, and historical heritage. Engage in local festivals, visit historical sites and interact with locals. Strike up conversations with locals to learn about their way of life, traditions, and customs. Hospitality is an integral part of the culture, and people are generally welcoming to visitors.",
      },
      {
        title: "Trekking Opportunities in KP Province",
        content:
          "Khyber Pakhtunkhwa offers numerous trails of trekking opportunities, especially in its mountainous regions and is also known as paradise of trekkers. The province is home to some of the most scenic and challenging trekking trails, providing adventure enthusiasts with a chance to explore breathtaking landscapes and experience the natural beauty of the area. The most famous and adventurous treks are located in Chitral, Swat, Dir, and Hazara Division.",
      },
      {
        title: "Local Dishes to Try in KP Province",
        content:
          "Khyber Pakhtunkhwa offers a variety of delicious and flavorful local dishes that showcase the region's culinary richness. Here are some traditional dishes that you should try while exploring KP Province: Chapli kebab, Peshawari naan, Pulao, Karahi tikka, Dumpukh, Qahwa (Green Tea), Kabuli bread, Peshawari Faluda.",
      },
      {
        title: "Travel Within KP Province",
        content:
          "Traveling within Khyber Pakhtunkhwa (KP) Province in Pakistan can be done through various modes of transportation. The choice of transportation depends on your destination, budget, and preferences. Here are some common ways to travel within KP Province: Road transport, Buses and Coaches, Taxis and Rickshaws, Rent a Car, Rail transport, Airports, Motorcycles. Tour operators and Guides: If you plan to explore specific tourist destinations or engage in trekking or adventure activities, consider hiring local tour operators or guides who can arrange transportation as part of a package.",
      },
      {
        title: "Accommodations in KP Province",
        content:
          "Khyber Pakhtunkhwa offers a range of accommodations that suits travelers of various budget preferences, from budget-friendly options to more luxurious establishments. Whether you're exploring cities, valleys, or mountainous regions, you can find a variety of lodging choices. Here are some categories of accommodations available in KP Province: Budget Hotels, Guesthouses, Mid-Range Hotels, Luxury Hotels, Resorts, Mountain Huts, Camps, Online Booking Platforms.",
      },
      {
        title: "Visit Archaeological Sites in KP Province",
        content:
          "Absolutely! Explore the ancient ruins of Taxila and the Buddhist heritage in Swat Valley for a glimpse into the region's rich history. Khyber Pakhtunkhwa is rich in archaeological sites with a deep historical significance. The province is home to remnants of ancient civilizations, Buddhist stupas, and other archaeological wonders. Here are some notable archaeological sites you can visit in KP Province: Takht-I-Bahi, Butkara Stupa, Udegram Buddhist Monastery, Jamal Garhi Buddhist Monastery, Peshawar Museum, Kafir Kot Archaeological Site.",
      },
      {
        title: "Popular Outdoor Activities in KP Province",
        content:
          "Khyber Pakhtunkhwa (KP) offers a diverse range of outdoor activities due to its varied landscapes, which include mountains, valleys, rivers, and historical sites. Here are some popular outdoor activities you can enjoy in KP Province: Trekking And Hiking, Camping, White-Water Rafting, Skiing and Snow Sports, Fishing, Mountain Biking, Rock Climbing and Mountaineering, Paragliding, Heritage and Cultural Tours, Trophy Hunting, Photography and Bird Watching.",
      },
      {
        title: "Visa Policy for KP Province",
        content:
          "Under the new visa policy announced by the Government of Pakistan, 175 countries can apply for visas online. While 50 countries having MOU with Government can avail the facility of on arrival visa. Minimum time for issuing visa is fourteen days and maximum time is thirty days.",
      },
      {
        title: "Investment Opportunities in KP",
        content:
          "Khyber Pakhtunkhwa Government under KP-Tourism Act 2019 offers investment in tourism sector through declared Integrated Tourism Zones (ITZs) for relevant projects. Moreover, KP Government is also offering private sector the (PTDC) hotels, motels, Rest Houses and Camping Pods for lease to private sector for Investment.",
      },
      {
        title: "Multi-Lingual Guides",
        content:
          "The tour operators who are dealing with Foreign Tourists provide the facility of guides who can speak multiple languages such as French, German, Chinese, Japanese, etc.",
      },
      {
        title: "International Flights to KP",
        content:
          "In Khyber Pakhtunkhwa Bacha Khan International Airport Peshawar receives international flights for multiple Airlines.",
      },
      {
        title: "Road Conditions in KP",
        content:
          "All the main roads connecting famous tourism areas in Khyber Pakhtunkhwa are connected through infrastructure of asphalt roads. Whereas the link roads to far-flung areas are available but the condition is not good.",
      },
      {
        title:
          "Benefits of Investing in Pakistan's Tourism Sector through PPPs",
        content:
          "• High returns: Pakistan's tourism industry is projected to grow at a staggering 7.8% annually, offering lucrative investment opportunities. • Government support: The PPP Act provides streamlined project approval, risk-sharing mechanisms, and dispute resolution frameworks. • Untapped potential: From majestic mountains to vibrant deserts and ancient heritage sites, Pakistan's diverse tourism offerings remain largely unexplored.",
      },
      {
        title: "PPP Act Facilitation for Tourism Investment",
        content:
          "• Fast-track approvals: The Act eliminates bureaucratic hurdles, reducing project lead times significantly. • Risk allocation: Risks are shared between the public and private sectors, mitigating investor concerns. • Transparent process: Clear guidelines and regulations ensure fair competition and ethical partnerships.",
      },
      {
        title: "Types of Tourism Projects Eligible for PPP Investment",
        content:
          "• Development and management of tourist destinations: Build eco-friendly resorts, adventure parks, and cultural hubs. • Revitalization of historical sites: Restore and manage forts, museums, and archaeological treasures. • Modernization of tourism infrastructure: Upgrade airports, transportation networks, and communication systems.",
      },
      {
        title: "Key Government Agencies for PPP Tourism Projects",
        content:
          "• Board of Investment (BOI): The BOI acts as a one-stop shop for investors, facilitating project approvals and offering incentives. • Ministry of Tourism: The Ministry develops tourism policies, identifies potential projects, and promotes PPP initiatives. • PPP Unit: The PPP Unit provides technical expertise, guides investors through the process, and manages project implementation.",
      },
      {
        title: "Minimum Investment Requirements for PPP Tourism Projects",
        content:
          "Minimum investment amounts vary depending on the project size and complexity. Smaller projects might require investments around PKR 10 million while larger ones could go up to PKR 10 billion (USD 55 million).",
      },
      {
        title: "Land Tenure Options for PPP Tourism Projects",
        content:
          "• Lease: Investors can lease land from the government for a specific period to develop and operate tourism facilities. • Concession: The government grants the right to develop and manage a specific tourism asset, like a national park or historical site. • Build-Operate-Transfer (BOT): Investors build and operate a tourism facility for a defined period before transferring ownership back to the government.",
      },
      {
        title: "Tax Incentives for PPP Tourism Investors",
        content:
          "• Tax holidays: Investors can enjoy exemption from corporate income tax and customs duties for initial years. • Accelerated depreciation: Faster depreciation allowances reduce taxable income and increase returns. • Subsidies and grants: The government may offer financial assistance for specific PPP tourism projects.",
      },
      {
        title: "Security Measures for Tourism Investments",
        content:
          "• Enhanced security measures: The government has deployed dedicated security forces in key tourist areas. • Risk mitigation strategies: PPP agreements can include provisions for security contingencies and compensation in case of unforeseen events. • Collaboration with private security firms: Investors can partner with private security companies for additional protection.",
      },
      {
        title: "Dispute Resolution Mechanisms for PPP Tourism Projects",
        content:
          "• Negotiation and mediation: Initial attempts are made to resolve disputes amicably through discussions and mediation. • Arbitration: If negotiations fail, an independent arbitration tribunal settles the dispute according to the PPP agreement. • Local courts: As a last resort, disputes can be referred to local courts for legal adjudication.",
      },
      {
        title: "More Information about PPP Tourism Opportunities",
        content:
          "• Board of Investment website: www.boi.gov.pk • Special investment Facilitation Council www.sifc.gov.pk",
      },
    ],
  };
  const [scrolling, setScrolling] = useState(false);
  const [activeSector, setActiveSector] = useState("General");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sector) {
      setActiveSector(sector);
    } else {
      setActiveSector("General");
    }
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent",
    transition: "background-color 0.3s ease",
    color: scrolling ? "#000" : "#fff",
  };
  const handleSectorChange = (sector) => {
    if (sector !== activeSector) {
      setActiveSector(sector);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div className="bgFAQ">
        <div
          className="relative bg-cover bg-center h-[256px] md:h-[554px] overflow-hidden bg-white bgFAQ"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 z-50 "
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-4 md:p-10">
                <h1 className="aboutusHeading !text-center font-bold !text-white !text-3xl md:!text-6xl">
                  Frequently Asked Questions
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="faqs" className="bg-[#F8FAFB]">
        <div className="block md:hidden">
          <Menu as="div" className="relative m-4 text-left">
            <div>
              <Menu.Button className="AgricultureMainHeading items-center !text-2xl py-4 inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                What Do You Need Help With?{" "}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-black"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className=" right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => handleSectorChange("General")}
                        className={classNames(
                          active
                            ? "bg-gray-100 !text-gray-900 "
                            : "!text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        SIFC
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() =>
                          handleSectorChange("investmentClimateFaqData")
                        }
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Investment Climate
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => handleSectorChange("Agriculture")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Agriculture
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleSectorChange("ITTelecom")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        IT & Telecom
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleSectorChange("Mines")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Mines & Minerals
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleSectorChange("Energy")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Energy (Petroleum & Power)
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleSectorChange("Industry")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Industry
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleSectorChange("tourism")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Tourism & Privatisation
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="pt-8 md:pt-[150px]  pb-[100px] hidden md:flex flex-col justify-center items-center gap-4">
          <div className="AgricultureMainHeading !text-5xl mx-4">
            What Do You Need Help With?{" "}
          </div>
          {/* <div className="AgricultureMainText contianer">
            Need something cleared up?
          </div> */}
        </div>
        <div className="w-full flex flex-row mb-4 md:mb-[150px] justify-center items-center">
          <div className="contianer-width ">
            <div className="flex flex-col md:grid md:grid-cols-12 mx-4 gap-4">
              <div className="col-span-3 hidden md:block  rounded-xl">
                <div className="text-[#329244] bg-[#ffff] p-4 lg:p-[30px] text-[30px] font-bold Crimson-Text">
                  {/* Table of content */}

                  <div className="flex flex-wrap xl:flex-col mt-[20px] justify-between gap-4">
                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("General")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "General"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        SIFC
                      </div>
                      <div>
                        {activeSector === "General" ? (
                          <hr className="w-[110px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() =>
                          handleSectorChange("investmentClimateFaqData")
                        }
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "investmentClimateFaqData"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Investment Climate
                      </div>
                      <div>
                        {activeSector === "investmentClimateFaqData" ? (
                          <hr className="w-[110px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("Agriculture")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "Agriculture"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Agriculture
                      </div>
                      <div>
                        {activeSector === "Agriculture" ? (
                          <hr className="w-[100px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("ITTelecom")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "ITTelecom"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        IT & Telecom
                      </div>
                      <div>
                        {activeSector === "ITTelecom" ? (
                          <hr className="w-[110px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("Mines")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "Mines"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Mines & Minerals
                      </div>
                      <div>
                        {activeSector === "Mines" ? (
                          <hr className="w-[100px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("Energy")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "Energy"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Energy (Petroleum & Power)
                      </div>
                      <div>
                        {activeSector === "Energy" ? (
                          <hr className="w-[40px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("Industry")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "Industry"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Industry
                      </div>
                      <div>
                        {activeSector === "Industry" ? (
                          <hr className="w-[40px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div
                        onClick={() => handleSectorChange("tourism")}
                        className={`cursor-pointer font-normal Poppins text-[18px] ${
                          activeSector === "tourism"
                            ? "text-[#073332] font-normal"
                            : "text-[#737C87] font-bold"
                        }`}
                      >
                        Tourism & Privatization
                      </div>
                      <div>
                        {activeSector === "tourism" ? (
                          <hr className="w-[40px] border border-[#F08A23] bg-[#F08A23]"></hr>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-9 rounded">
                <div className="accordion w-full">
                  {accordionData[activeSector].map(({ title, content }) => (
                    <FaqAccordian title={title} content={content} />
                  ))}
                  <div className="my-[10px] w-full gap-4 accordion items-center bg-[#073332] !text-white flex flex-wrap rounded-lg lg:justify-between justify-center p-8">
                    <div>
                      <div className="!text-white headingTestonimial text-[24px] md:text-[48px]">
                        Connect With Us{" "}
                      </div>
                      <a
                        href="mailto:invest@sifc.gov.pk"
                        className="!text-[#83CEC1] block text-center w-full lg:text-left italic underline-offset-1 underline"
                      >
                        invest@sifc.gov.pk{" "}
                      </a>
                    </div>
                    <Link to="/contact">
                      {" "}
                      <button
                        type="button"
                        class="text-white w-[217px] h-[56px] font-medium text-sm px-5 py-2.5 bg-[#2F7F3D]"
                      >
                        Contact us
                      </button>
                    </Link>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default FAQs;
