import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import "./WhyInvest.css";
import invest1 from "../../assests/images/invest1.png";
import invest2 from "../../assests/images/invest2.png";
import invest3 from "../../assests/images/invest3.png";
import invest4 from "../../assests/images/invest4.png";
import invest5 from "../../assests/images/invest5.png";
import invest6 from "../../assests/images/invest6.png";
import invest7 from "../../assests/images/invest7.png";
import invest8 from "../../assests/images/invest8.png";
import invest9 from "../../assests/images/invest9.png";
import invest10 from "../../assests/images/invest10.png";
import invest11 from "../../assests/images/invest11.png";
import invest12 from "../../assests/images/invest12.png";
import invest13 from "../../assests/images/invest13.png";
import invest14 from "../../assests/images/invest14.png";
import invest15 from "../../assests/images/invest15.png";
import invest16 from "../../assests/images/invest16.png";
import invest17 from "../../assests/images/invest17.png";
import invest18 from "../../assests/images/invest18.png";
import invest19 from "../../assests/images/invest19.png";
import invest20 from "../../assests/images/invest20.png";
import invest21 from "../../assests/images/invest21.png";
import invest22 from "../../assests/images/invest22.png";
import invest23 from "../../assests/images/invest23.png";
import invest24 from "../../assests/images/invest24.png";
import invest25 from "../../assests/images/invest25.png";
import invest26 from "../../assests/images/invest26.png";
import invest27 from "../../assests/images/invest27.png";
import invest28 from "../../assests/images/invest28.png";
import invest29 from "../../assests/images/invest29.png";
import invest30 from "../../assests/images/invest30.png";
import invest31 from "../../assests/images/invest31.png";
import invest32 from "../../assests/images/invest32.png";
import invest33 from "../../assests/images/invest33.png";
import invest34 from "../../assests/images/invest34.png";
import invest35 from "../../assests/images/invest35.png";
import invest36 from "../../assests/images/invest36.png";
import invest37 from "../../assests/images/invest37.png";
import invest38 from "../../assests/images/invest38.png";
import invest39 from "../../assests/images/invest39.png";
import invest40 from "../../assests/images/invest40.png";
import invest41 from "../../assests/images/invest41.png";
import invest42 from "../../assests/images/invest42.png";
import invest43 from "../../assests/images/invest43.png";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../subcomponents/footer";
import Slider from "react-slick";
export default function WhyInvest() {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  var settings1 = {
    dots: true,
    infinite: true,
    arrows: false,

    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    initialSlide: 0,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="bgPictureInvestmentClimate">
        <div
          className="relative bg-cover bg-center h-[300px] md:h-[554px] overflow-hidden bg-white bgPictureInvestmentClimate"
          style={{
            transition: "background-image 1s ease",
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center md:p-10">
                <h1 className="missionBoxHeading !text-[30px] md:!text-5xl">
                  Why Invest in Pakistan?
                </h1>{" "}
                <br></br>
                {/* <p className="leaderdesig !text-xl">
                  Lorem ipsum dolor sit amet consectetur. Justo ultrices.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="geo-strategy"
        className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10">
            Geo-Strategic Location<br></br>
            <span className="WP_text mx-4 ">
              Pakistan strategically stands at the crossroads of South Asia,
              Central Asia, & the Middle East, presenting an unparalleled
              gateway for global economic opportunities
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest">
          <div className="mx-4 mt-4 md:mt-[45px] mb-8 md:mb-[70px] hidden flex-row flex-wrap md:grid grid-cols-6 gap-[30px] place-content-center ">
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest1}></img>
              <div className="WP_text">Economic Gateway</div>
              <div className="whyInvestPakistan">
                Transit route for regional trade & seamless connectivity{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest2}></img>
              <div className="WP_text">Energy Corridors </div>
              <div className="whyInvestPakistan">
                Connects energy-rich Central Asian states to rest of the world{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest3}></img>
              <div className="WP_text">Strategic Maritime Gateway</div>
              <div className="whyInvestPakistan">
                Key maritime link to Arabian Sea, Indian Ocean & international
                shipping routes{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-start-2 col-span-2">
              <img src={invest4}></img>
              <div className="WP_text">Connectivity Hub</div>
              <div className="whyInvestPakistan">
                Integrates land-sea routes for seamless connectivity{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest5}></img>
              <div className="WP_text">Digital Land Bridge</div>
              <div className="whyInvestPakistan">
                Efficiently connecting China, and Central Asia with the global
                tech landscape{" "}
              </div>
            </div>
          </div>
          <div className="mx-4 mt-4 md:mt-[45px] mb-8 md:mb-[70px] block md:hidden gap-[30px] place-content-center ">
            <Slider {...settings1}>
              <div className="py-[40px] WhyInvestBox col-span-2">
                <img src={invest1}></img>
                <div className="WP_text">Economic Gateway</div>
                <div className="whyInvestPakistan">
                  Transit route for regional trade & seamless connectivity{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest2}></img>
                <div className="WP_text">Energy Corridors </div>
                <div className="whyInvestPakistan">
                  Connects energy-rich Central Asian states to rest of the world{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest3}></img>
                <div className="WP_text">Strategic Maritime Gateway</div>
                <div className="whyInvestPakistan">
                  Key maritime link to Arabian Sea, Indian Ocean & international
                  shipping routes{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-start-2 col-span-2">
                <img src={invest4}></img>
                <div className="WP_text">Connectivity Hub</div>
                <div className="whyInvestPakistan">
                  Integrates land-sea routes for seamless connectivity{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest5}></img>
                <div className="WP_text">Digital Land Bridge</div>
                <div className="whyInvestPakistan">
                  Efficiently connecting China & Central Asia with the global
                  tech landscape{" "}
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div
        id="young_&_skilled"
        className="bg-investment-1 flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10 !text-[#F08A23]">
            Vibrant, Young & Skilled Workforce<br></br>
            <span className="WP_text !text-white mx-4">
              Being the 5th most populous country with a median age of 20.6
              years, our youthful talent pool stands ready to drive innovation,
              making Pakistan your ideal investment destination
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="mx-4 mt-4 md:mt-[45px] mb-8 md:mb-[70px] hidden md:flex flex-row flex-wrap gap-[30px] place-content-center ">
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest16}></img>
              <div className="WP_text !text-white">71.76 Million</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Total labor force{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest14}></img>
              <div className="WP_text !text-white">64% of Total Population</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Aged below 30{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest15}></img>
              <div className="WP_text !text-white">Among 10 Largest</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Labor forces globally{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest17}></img>
              <div className="WP_text !text-white">0.4 Million</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Skilled people join the labour force annually{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest18}></img>
              <div className="WP_text !text-white">Cost Effective IT Labor</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                70% less than North America & Europe{" "}
              </div>
            </div>
          </div>
          <Slider className="mx-2 md:!hidden" {...settings1}>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest16}></img>
              <div className="WP_text !text-white">71.76 Million</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Total labor force{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest14}></img>
              <div className="WP_text !text-white">64% of Total Population</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Aged below 30{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest15}></img>
              <div className="WP_text !text-white">Among 10 Largest</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Labor forces globally{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest17}></img>
              <div className="WP_text !text-white">0.4 Million</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Skilled people join the labour force annually{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest18}></img>
              <div className="WP_text !text-white">Cost Effective IT Labor</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                70% less than North America & Europe{" "}
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div
        id="EnormousPotential"
        className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10">
            Enormous Potential<br></br>
            <span className="WP_text mx-4">
              Step into panorama of possibilities—a holistic canvas promising
              diverse growth & innovation for visionary investors
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="mx-4 mt-4 md:mt-[45px] mb-8 md:mb-[70px] hidden flex-row flex-wrap md:grid grid-cols-6 gap-[30px] place-content-center ">
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest26}></img>
              <div className="WP_text">9.1 Million Hectares</div>
              <div className="whyInvestPakistan">
                Available land for agriculture{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest26}></img>
              <div className="WP_text">22.4 Million Hectares</div>
              <div className="whyInvestPakistan">Rangeland for livestock</div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest27}></img>
              <div className="WP_text">2nd Largest Freelancing</div>
              <div className="whyInvestPakistan">
                Software development & technology
              </div>
            </div>
            {/* <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest28}></img>
              <div className="WP_text">$ 22.2b</div>
              <div className="whyInvestPakistan">IT Export Potential </div>
            </div> */}
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest29}></img>
              <div className="WP_text">$ 6.1 Trillion</div>
              <div className="whyInvestPakistan">
                Net estimated mineral reserves{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest30}></img>
              <div className="WP_text">5th Largest</div>
              <div className="whyInvestPakistan">Copper reserves</div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest31}></img>
              <div className="WP_text">2nd Largest</div>
              <div className="whyInvestPakistan">Coal reserves </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2 col-start-2">
              <img src={invest32}></img>
              <div className="WP_text ">3300 GW</div>
              <div className="whyInvestPakistan">
                Renewable energy potential
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest33}></img>
              <div className="WP_text">1.5x Increasing</div>
              <div className="whyInvestPakistan">Energy demand by 2030 </div>
            </div>
            {/* <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest34}></img>
              <div className="WP_text">20,000km</div>
              <div className="whyInvestPakistan">Transmission Lines </div>
            </div> */}
          </div>
          <div className="mx-4 mt-4 md:mt-[45px] mb-8 md:mb-[70px] block md:hidden gap-[30px] place-content-center ">
            <Slider {...settings1}>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest26}></img>
                <div className="WP_text">9.1 Million Hectares</div>
                <div className="whyInvestPakistan">
                  Available Land for agriculture{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest26}></img>
                <div className="WP_text">22.4 Million Hectares</div>
                <div className="whyInvestPakistan">Rangeland for livestock</div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest27}></img>
                <div className="WP_text">2nd Largest Freelancing</div>
                <div className="whyInvestPakistan">
                  Software development & technology
                </div>
              </div>
              {/* <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest28}></img>
              <div className="WP_text">$ 22.2b</div>
              <div className="whyInvestPakistan">IT Export Potential </div>
            </div> */}
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest29}></img>
                <div className="WP_text">$ 6.1 Trillions</div>
                <div className="whyInvestPakistan">
                  Net estimated mineral reserves{" "}
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest30}></img>
                <div className="WP_text">5th Largest</div>
                <div className="whyInvestPakistan">Copper reserves</div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest31}></img>
                <div className="WP_text">2nd Largest</div>
                <div className="whyInvestPakistan">Coal reserves </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2 col-start-2">
                <img src={invest32}></img>
                <div className="WP_text ">3300 GW</div>
                <div className="whyInvestPakistan">
                  Renewable energy potential
                </div>
              </div>
              <div className="  py-[40px] WhyInvestBox col-span-2">
                <img src={invest33}></img>
                <div className="WP_text">1.5x Increasing</div>
                <div className="whyInvestPakistan">Energy demand by 2030 </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div
        id="NetworkofEconomic"
        className="bg-investment-3 flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10 !text-[#F08A23]">
            Network of Economic & Tech Zones<br></br>
            <span className="WP_text !text-white">
              Embark on a transformative journey within Pakistan's Extensive
              Economic & Tech Zones - diverse, incentivized & globally
              compliant, offering boundless opportunities
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="mt-[45px] mb-[70px] hidden md:flex flex-row flex-wrap gap-[30px] place-content-center ">
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest19}></img>
              <div className="WP_text !text-white">Diverse Economic Zones</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                SEZs, STZs, STPs, EPZs & Gwadar Free Trade Zone
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest20}></img>
              <div className="WP_text !text-center !text-white">
                National Economic Zones Development & Regulatory Authority
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Rejuvenating industrial & tech development{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest21}></img>
              <div className="WP_text !text-center !text-white">
                Investment Incentives
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Robust incentive packages, tax exemptions & reduced tariffs{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest22}></img>
              <div className="WP_text !text-center !text-white">
                State-of-the-art Infrastructure Facilities
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Access to reliable utilities, modern infrastructure,
                transportation, & communication networks
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest23}></img>
              <div className="WP_text !text-white !text-center">
                Enterprise Flexibility
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Choose from public, private, PPP or sole Enterprise SEZs{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest43}></img>
              <div className="WP_text !text-center !text-white">
                Allied Infrastructure
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Projects fuel integrated growth, developing communication
                infrastructure to link economic zones{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest24}></img>
              <div className="WP_text !text-white !text-center">
                International Quality & Safety Standards
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Global business compliance{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest25}></img>
              <div className="WP_text !text-white">
                Environmental Sustainability
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Aligned with global eco-friendly practices
              </div>
            </div>
          </div>
          <Slider className="mx-2 md:!hidden" {...settings1}>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest19}></img>
              <div className="WP_text !text-white">Diverse Economic Zones</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                SEZs, STZs, STPs, EPZs & Gwadar Free Trade Zone
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest20}></img>
              <div className="WP_text !text-center !text-white">
                National Economic Zones Development & Regulatory Authority
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Rejuvenating industrial & tech development{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest21}></img>
              <div className="WP_text !text-center !text-white">
                Investment Incentives
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Robust incentive packages, tax exemptions & reduced tariffs{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest22}></img>
              <div className="WP_text !text-center !text-white">
                State-of-the-art Infrastructure Facilities
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Access to reliable utilities, modern infrastructure,
                transportation & communication networks
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest23}></img>
              <div className="WP_text !text-white !text-center">
                Enterprise Flexibility
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Choose from public, private, PPP or sole Enterprise SEZs{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest43}></img>
              <div className="WP_text !text-center !text-white">
                Allied Infrastructure
              </div>
              <div className="whyInvestPakistan !text-center !text-white opacity-70">
                Projects fuel integrated growth, developing communication
                infrastructure to link economic zones{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest24}></img>
              <div className="WP_text !text-white !text-center">
                International Quality & Safety Standards
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Global business compliance{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest25}></img>
              <div className="WP_text !text-white">
                Environmental Sustainability
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Aligned with global eco-friendly practices
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div
        id="Liberal"
        className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10">
            Liberal Investment Regime<br></br>
            <span className="WP_text mx-4">
              Explore Pakistan's inviting investment climate where proactive
              policies, regulatory ease & strategic initiatives beckon global
              prosperity
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest">
          <div className="mt-[45px] mb-[70px] hidden flex-row flex-wrap md:grid grid-cols-6 gap-[30px] place-content-center mx-4">
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest6}></img>
              <div className="WP_text">Level Playing Field</div>
              <div className="whyInvestPakistan">
                Equal treatment to local & foreign investors{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest7}></img>
              <div className="WP_text">Foreign Equity </div>
              <div className="whyInvestPakistan">Up to 100% </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest8}></img>
              <div className="WP_text">Profits Repatriation</div>
              <div className="whyInvestPakistan">
                Remittances of Royalty, fees, & profits{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest9}></img>
              <div className="WP_text">Financing</div>
              <div className="whyInvestPakistan">
                Borrowing facilities from local & foreign banks{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest10}></img>
              <div className="WP_text">USD Retention </div>
              <div className="whyInvestPakistan">
                Upto 50% for tech industry
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest11}></img>
              <div className="WP_text">Foriegn Curreny Visa Debit Cards</div>
              <div className="whyInvestPakistan">
                Introduction of FCY Debit Cards{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-start-3 col-span-2">
              <img src={invest12}></img>
              <div className="WP_text">Ease of Doing Business</div>
              <div className="whyInvestPakistan">
                Investment friendly regime
              </div>
            </div>
            {/* <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest13}></img>
              <div className="WP_text"> Repatriation Procedures</div>
              <div className="whyInvestPakistan">
                Net Estimated Mineral Wealth{" "}
              </div>
            </div> */}
          </div>
          <Slider className="mx-2 md:!hidden" {...settings1}>
            {" "}
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest6}></img>
              <div className="WP_text">Level Playing Field</div>
              <div className="whyInvestPakistan">
                Equal treatment to local & foreign investors{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest7}></img>
              <div className="WP_text">Foreign Equity </div>
              <div className="whyInvestPakistan">Up to 100% </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest8}></img>
              <div className="WP_text">Profits Repatriation</div>
              <div className="whyInvestPakistan">
                Remittances of Royalty, fees & profits{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest9}></img>
              <div className="WP_text">Financing</div>
              <div className="whyInvestPakistan">
                Borrowing facilities from local & foreign Banks{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest10}></img>
              <div className="WP_text">USD Retention </div>
              <div className="whyInvestPakistan">
                Up to 50% for tech industry
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-span-2">
              <img src={invest11}></img>
              <div className="WP_text">Foriegn Curreny Visa Debit Cards</div>
              <div className="whyInvestPakistan">
                Introduction of FCY Debit Cards{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBox col-start-3 col-span-2">
              <img src={invest12}></img>
              <div className="WP_text">Ease of Doing Business</div>
              <div className="whyInvestPakistan">
                Investment friendly regime
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div
        id="Modernized"
        className="bg-investment-2 flex justify-center items-center flex-col py-8 md:py-[150px]"
      >
        <div className="flex-col justify-center items-center flex">
          <div className="headingInvestInPakistan !text-[30px] md:!text-[40px] mb-4 md:mb-[40px] !leading-10 !text-[#F08A23]">
            Modernized Infrastructure Landscape<br></br>
            <span className="WP_text !text-white">
              Explore Pakistan's progressive infrastructure landscape offering
              state-of-the-art facilities, seamless connectivity & a dynamic
              ecosystem for innovation-driven success
            </span>
          </div>
        </div>
        <div className="container-width-Why-Invest ">
          <div className="mt-[45px] mb-[70px] hidden md:flex flex-row flex-wrap gap-[30px] place-content-center ">
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest35}></img>
              <div className="WP_text !text-white">1,046 km</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Strategic coastal line advantage
              </div>
              <div className="whyInvestPakistan !text-white opacity-70"></div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest36}></img>
              <div className="WP_text !text-white">Sea Ports</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Karachi Port & Port Qasim, among others facilitating 95%
                international trade activities{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest37}></img>
              <div className="WP_text !text-white">Deep-Sea Gwadar Port</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Strategic gateway to the Arabian Sea & the Indian Ocean{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest38}></img>
              <div className="WP_text !text-white">Dry Ports </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Inland hubs for integrated logistics efficiency
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest39}></img>
              <div className="WP_text !text-white">Strategic Land Ports</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Torkham, Chaman, Gwadar, Khunjerab & others for cross-border
                trade{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest40}></img>
              <div className="WP_text !text-white">259,678 km</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive highways, motorways & roads infrastructure{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest41}></img>
              <div className="WP_text !text-white">12,774 km </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive railway infrastructure{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest42}></img>
              <div className="WP_text !text-white">
                12 International Airports
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive airport infrastructure{" "}
              </div>
            </div>
          </div>
          <Slider className="mx-2 md:!hidden" {...settings1}>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest35}></img>
              <div className="WP_text !text-white">1,046 km</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Strategic coastal line advantage
              </div>
              <div className="whyInvestPakistan !text-white opacity-70"></div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest36}></img>
              <div className="WP_text !text-white">Sea Ports</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Karachi Port & Port Qasim, among others facilitating 95%
                international trade activities{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest37}></img>
              <div className="WP_text !text-white">Deep-Sea Gwadar Port</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Strategic gateway to the Arabian Sea & the Indian Ocean{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest38}></img>
              <div className="WP_text !text-white">Dry Ports </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Inland hubs for integrated logistics efficiency
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest39}></img>
              <div className="WP_text !text-white">Strategic Land Ports</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Torkham, Chaman, Gwadar, Khunjerab & others for cross-border
                trade{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest40}></img>
              <div className="WP_text !text-white">259,678 km</div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive highways, motorways & roads infrastructure{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest41}></img>
              <div className="WP_text !text-white">12,774 km </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive railway infrastructure{" "}
              </div>
            </div>
            <div className="  py-[40px] WhyInvestBoxSecond">
              <img src={invest42}></img>
              <div className="WP_text !text-white">
                12 International Airports
              </div>
              <div className="whyInvestPakistan !text-white opacity-70">
                Extensive airport infrastructure{" "}
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
}
