import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import tiktok from "../../assests/Icons/tiktok.png";
import thread from "../../assests/Icons/tthreads.png";
function Footer() {
  return (
    <>
      <div className="py-[100px] bg-[#F8F8F8] overflow-hidden border border-[#83CEC1] flex justify-center item-center">
        <div className="flex w-full lg:justify-between gap-[30px] lg:flex-nowrap lg:flex-row flex-col justify-center flex-wrap lg:text-left text-center mx-4 container-width-SIFC-faclitates">
          <div className="flex flex-col">
            <div className="window !text-[30px] md:!text-[37px]">
              Your One Window for<br></br>{" "}
              <span className="investment !text-[30px] md:!text-[37px]">
                investment facilitation
              </span>
            </div>
            <div className="STAYcoNNECTED mb-[5px] mt-[40px]">
              Stay Connected
            </div>

            <div className="flex flex-row justify-center lg:justify-start my-4 gap-2 md:gap-[16px]">
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a
                  href="https://www.linkedin.com/company/sifcpakistan/"
                  target="_blank"
                >
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5 0.5C5.5975 0.5 0 6.0975 0 13C0 19.9025 5.5975 25.5 12.5 25.5C19.4025 25.5 25 19.9025 25 13C25 6.0975 19.4025 0.5 12.5 0.5ZM8.86765 19.3965H5.82333V10.2376H8.86765V19.3965ZM7.34558 8.98694H7.32574C6.30417 8.98694 5.64346 8.2837 5.64346 7.40479C5.64346 6.50605 6.32439 5.82227 7.3658 5.82227C8.40721 5.82227 9.04808 6.50605 9.06792 7.40479C9.06792 8.2837 8.40721 8.98694 7.34558 8.98694ZM19.8448 19.3965H16.8009V14.4967C16.8009 13.2653 16.3601 12.4255 15.2586 12.4255C14.4176 12.4255 13.9168 12.992 13.6967 13.5388C13.6162 13.7345 13.5965 14.008 13.5965 14.2817V19.3965H10.5524C10.5524 19.3965 10.5923 11.0968 10.5524 10.2376H13.5965V11.5344C14.0011 10.9103 14.7249 10.0226 16.3401 10.0226C18.343 10.0226 19.8448 11.3316 19.8448 14.1448V19.3965Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a
                  href="https://www.instagram.com/sifcpakistan/"
                  target="_blank"
                >
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="fff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.8926 13C14.8926 14.3214 13.8214 15.3926 12.5 15.3926C11.1786 15.3926 10.1074 14.3214 10.1074 13C10.1074 11.6786 11.1786 10.6074 12.5 10.6074C13.8214 10.6074 14.8926 11.6786 14.8926 13Z"
                      fill="#073332"
                    />
                    <path
                      d="M18.0954 8.76683C17.9804 8.45517 17.7969 8.17307 17.5585 7.94152C17.3269 7.7031 17.045 7.51961 16.7332 7.4046C16.4803 7.30637 16.1003 7.18945 15.4005 7.1576C14.6435 7.12308 14.4165 7.11564 12.5 7.11564C10.5833 7.11564 10.3563 7.12289 9.59949 7.15741C8.89969 7.18945 8.51955 7.30637 8.26683 7.4046C7.95498 7.51961 7.67288 7.7031 7.44152 7.94152C7.2031 8.17307 7.01961 8.45498 6.90441 8.76683C6.80618 9.01974 6.68926 9.39988 6.65741 10.0997C6.62289 10.8565 6.61545 11.0835 6.61545 13.0002C6.61545 14.9167 6.62289 15.1437 6.65741 15.9007C6.68926 16.6005 6.80618 16.9804 6.90441 17.2334C7.01961 17.5452 7.20291 17.8271 7.44133 18.0587C7.67288 18.2971 7.95479 18.4806 8.26664 18.5956C8.51955 18.694 8.89969 18.8109 9.59949 18.8428C10.3563 18.8773 10.5831 18.8846 12.4998 18.8846C14.4167 18.8846 14.6437 18.8773 15.4003 18.8428C16.1001 18.8109 16.4803 18.694 16.7332 18.5956C17.3592 18.3541 17.8539 17.8594 18.0954 17.2334C18.1936 16.9804 18.3105 16.6005 18.3426 15.9007C18.3771 15.1437 18.3844 14.9167 18.3844 13.0002C18.3844 11.0835 18.3771 10.8565 18.3426 10.0997C18.3107 9.39988 18.1938 9.01974 18.0954 8.76683ZM12.5 16.6858C10.4643 16.6858 8.81405 15.0357 8.81405 13C8.81405 10.9643 10.4643 9.31424 12.5 9.31424C14.5355 9.31424 16.1858 10.9643 16.1858 13C16.1858 15.0357 14.5355 16.6858 12.5 16.6858ZM16.3315 10.0299C15.8558 10.0299 15.4701 9.64421 15.4701 9.16852C15.4701 8.69283 15.8558 8.30716 16.3315 8.30716C16.8072 8.30716 17.1928 8.69283 17.1928 9.16852C17.1926 9.64421 16.8072 10.0299 16.3315 10.0299Z"
                      fill="#fff"
                    />
                    <path
                      d="M12.5 0.5C5.5975 0.5 0 6.0975 0 13C0 19.9025 5.5975 25.5 12.5 25.5C19.4025 25.5 25 19.9025 25 13C25 6.0975 19.4025 0.5 12.5 0.5ZM19.6344 15.9593C19.5997 16.7233 19.4782 17.245 19.3008 17.7016C18.928 18.6658 18.1658 19.428 17.2016 19.8008C16.7452 19.9782 16.2233 20.0995 15.4594 20.1344C14.694 20.1693 14.4495 20.1777 12.5002 20.1777C10.5507 20.1777 10.3064 20.1693 9.54075 20.1344C8.77686 20.0995 8.255 19.9782 7.79858 19.8008C7.31945 19.6206 6.88572 19.3381 6.52714 18.9729C6.16207 18.6145 5.87959 18.1805 5.69935 17.7016C5.52197 17.2452 5.40047 16.7233 5.36575 15.9594C5.33047 15.1938 5.32227 14.9493 5.32227 13C5.32227 11.0507 5.33047 10.8062 5.36556 10.0407C5.40028 9.27666 5.52158 8.755 5.69897 8.29839C5.87921 7.81945 6.16188 7.38553 6.52714 7.02714C6.88553 6.66188 7.31945 6.3794 7.79839 6.19916C8.255 6.02177 8.77666 5.90047 9.54075 5.86556C10.3062 5.83066 10.5507 5.82227 12.5 5.82227C14.4493 5.82227 14.6938 5.83066 15.4593 5.86575C16.2233 5.90047 16.745 6.02177 17.2016 6.19897C17.6805 6.37921 18.1145 6.66188 18.4731 7.02714C18.8381 7.38572 19.1208 7.81945 19.3008 8.29839C19.4784 8.755 19.5997 9.27666 19.6346 10.0407C19.6695 10.8062 19.6777 11.0507 19.6777 13C19.6777 14.9493 19.6695 15.1938 19.6344 15.9593Z"
                      fill="#ffff"
                    />
                  </svg>
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a href="https://www.facebook.com/sifcpak" target="_blank">
                  {" "}
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 13C25 6.0957 19.4043 0.5 12.5 0.5C5.5957 0.5 0 6.0957 0 13C0 19.9043 5.5957 25.5 12.5 25.5C12.5732 25.5 12.6465 25.5 12.7197 25.4951V15.7686H10.0342V12.6387H12.7197V10.334C12.7197 7.66308 14.3506 6.20801 16.7334 6.20801C17.876 6.20801 18.8574 6.29102 19.1406 6.33008V9.12305H17.5C16.2061 9.12305 15.9521 9.73828 15.9521 10.6416V12.6338H19.0527L18.6475 15.7637H15.9521V25.0166C21.1768 23.5176 25 18.708 25 13Z"
                      fill="#ffff"
                    />
                  </svg>{" "}
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a href="https://www.youtube.com/@SIFCpakistan" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.943 15.3418L15.0091 13L10.943 10.6582V15.3418Z"
                      fill="#ffff"
                    />
                    <path
                      d="M12.5 0.5C5.5975 0.5 0 6.0975 0 13C0 19.9025 5.5975 25.5 12.5 25.5C19.4025 25.5 25 19.9025 25 13C25 6.0975 19.4025 0.5 12.5 0.5ZM20.3106 13.0128C20.3106 13.0128 20.3106 15.5478 19.989 16.7703C19.8088 17.4394 19.2812 17.9669 18.6121 18.147C17.3897 18.4688 12.5 18.4688 12.5 18.4688C12.5 18.4688 7.6231 18.4688 6.3879 18.1342C5.7188 17.9541 5.19123 17.4264 5.01099 16.7573C4.68922 15.5478 4.68922 13 4.68922 13C4.68922 13 4.68922 10.4651 5.01099 9.24271C5.19104 8.57362 5.73158 8.03307 6.3879 7.85302C7.61032 7.53125 12.5 7.53125 12.5 7.53125C12.5 7.53125 17.3897 7.53125 18.6121 7.8658C19.2812 8.04585 19.8088 8.57362 19.989 9.24271C20.3236 10.4651 20.3106 13.0128 20.3106 13.0128Z"
                      fill="#ffff"
                    />
                  </svg>{" "}
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a href="https://twitter.com/sifcpakistan" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.98569 6.6032H7.03796L16.0034 19.4274H17.9511L8.98569 6.6032Z"
                      fill="#073332"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 13C0 6.09668 5.59669 0.5 12.5 0.5C19.4033 0.5 25 6.09668 25 13C25 19.9033 19.4033 25.5 12.5 25.5C5.59669 25.5 0 19.9033 0 13ZM19.2186 5.64857L13.8675 11.8688L19.6871 20.3386H15.4068L11.4877 14.635L6.58105 20.3386H5.31294L10.9247 13.8157L5.31294 5.64857H9.59317L13.3042 11.0495L17.9506 5.64857H19.2186Z"
                      fill="#ffff"
                    />
                  </svg>
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a href="https://www.threads.net/@sifcpakistan" target="_blank">
                  <img src={thread} alt="Threads" />
                </a>
              </div>
              <div className="h-[40px] flex justify-center items-center p-2 w-[40px] bg-[#073332] rounded-full hover:bg-[#F08A23]">
                <a href="https://www.tiktok.com/@sifcpakistan" target="_blank">
                  <img src={tiktok} alt="Tiktok" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[24px] my-4">
            <div className="flex flex-col gap-[16px]">
              <div className="footertextheading">
                <Link to="why_invest_in_pakistan">Why Invest in Pakistan?</Link>
              </div>
              {/* <div className="footertext">Why  Pakistan?</div> */}
            </div>
            <div className="flex flex-col gap-[16px]">
              <div className="footertextheading">
                {" "}
                <Link to="/about">About Us</Link>
              </div>
              {/* <div className="footertext">
                {" "}
                <Link to="/about"> Why SIFC</Link>
              </div>
              <div className="footertext">
                {" "}
                <Link to="Leadership_org">Leadership & Organization</Link>
              </div> */}
              {/* <div className="footertextheading">
                {" "}
                <Link to="Establishbusinessinpakistan">
                  Start Your Business
                </Link>
              </div> */}

              <div className="footertextheading ">
                <Link to="/SifcVisa">SIFC Visa</Link>
              </div>
              <div className="footertextheading ">
                <Link to="/insights">Insights</Link>
              </div>
              <div className="footertextheading">
                {" "}
                <Link to="/tenders">Tenders </Link>
              </div>
              <div className="footertextheading">
                <Link to="/Partners">Partners</Link>
              </div>
              <div className="footertextheading ">
                <Link to="/contact">Contact Us</Link>
              </div>

              {/* <div className="footertext">Win-Win Paradigm</div> */}
              {/* <div className="footertext">SIFC Journey</div> */}
            </div>
          </div>
          <div className="hidden md:flex flex-col gap-[24px] my-4">
            <div className="flex flex-col gap-[16px]">
              <div className="footertextheading ">Important Links </div>
              <div className="footertext ">
                <a target="_blank" href="https://www.pc.gov.pk/">
                  {" "}
                  Ministry of Planning, Development & Special Initiatives{" "}
                </a>
              </div>
              <div className="footertext ">
                {" "}
                <a target="_blank" href="https://invest.gov.pk/">
                  {" "}
                  Federal Board of Investment
                </a>{" "}
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://www.mnfsr.gov.pk">
                  Ministry of National Food Security & Research
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://petroleum.gov.pk/">
                  Ministry of Energy (Petroleum Division)
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://power.gov.pk/">
                  Ministry of Energy (Power Division)
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://moip.gov.pk/">
                  Ministry of Industries & Production
                </a>
              </div>
              <div className="footertext ">
                <a
                  target="_blank"
                  href="https://timesagriculture.com/green-pakistan-initiative-land-information-and-management-system-lims/"
                >
                  Land Information & Management System{" "}
                </a>
              </div>

              {/* <div className="footertext ">
                <a target="_blank" href="https://tdap.gov.pk/">
                  Trade Development Authority of Pakistan
                </a>
              </div> */}
              {/* <div className="footertext ">
                <a target="_blank" href="https://gda.gov.pk/">
                  Gwadar Development Authority{" "}
                </a>
              </div> */}
            </div>
          </div>
          <div className="hidden md:flex flex-col gap-[24px] md:my-4 my-0">
            <div className="flex flex-col gap-[16px]">
              <div className="footertextheading md:block hidden ">
                Important Links{" "}
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://www.sbp.org.pk">
                  State Bank of Pakistan
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://mofa.gov.pk/">
                  Ministry of Foreign Affairs
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://www.finance.gov.pk/">
                  Ministry of Finance
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://most.gov.pk/">
                  Ministry of Science and Technology
                </a>
              </div>
              <div className="footertext ">
                <a target="_blank" href="https://www.secp.gov.pk/">
                  Securities & Exchange Commission of Pakistan
                </a>
              </div>
              {/* <div className="footertext ">
                <a target="_blank" href="https://tdap.gov.pk/">
                  Trade Development Authority of Pakistan
                </a>
              </div> */}
              {/* <div className="footertext ">
                <a target="_blank" href="https://gda.gov.pk/">
                  Gwadar Development Authority{" "}
                </a>
              </div> */}
            </div>
          </div>
          <div className="block md:hidden">
            <Menu as="div" className="relative m-4 text-left">
              <div>
                <Menu.Button className="footertextheading items-center py-4 inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Important Links{" "}
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-black"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className=" right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://www.sbp.org.pk">
                          State Bank of Pakistan
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://mofa.gov.pk/">
                          Ministry of Foreign Affairs
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://www.finance.gov.pk/">
                          Ministry of Finance
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://most.gov.pk/">
                          Ministry of Science and Technology
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://www.secp.gov.pk/">
                          Securities & Exchange Commission of Pakistan
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://www.pc.gov.pk/">
                          {" "}
                          Ministry of Planning, Development & Special
                          Initiatives{" "}
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        {" "}
                        <a target="_blank" href="https://invest.gov.pk/">
                          {" "}
                          Federal Board of Investment
                        </a>{" "}
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://www.mnfsr.gov.pk">
                          Ministry of National Food Security & Research
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://petroleum.gov.pk/">
                          Ministry of Energy (Petroleum Division)
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://power.gov.pk/">
                          Ministry of Energy (Power Division)
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a target="_blank" href="https://moip.gov.pk/">
                          Ministry of Industries & Production
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="footertext ">
                        <a
                          target="_blank"
                          href="https://timesagriculture.com/green-pakistan-initiative-land-information-and-management-system-lims/"
                        >
                          Land Information & Management System{" "}
                        </a>
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="w-full py-[20px] px-[10px] flex justify-center items-center bg-[#ECECEC] textfooterer">
        Copyright © 2025 All rights reserved | National Information Technology
        Board
      </div>
    </>
  );
}

export default Footer;
