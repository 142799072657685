import React, { useState, useEffect } from "react";
import "./Aboutus.css";
import NavBar from "../NavBar";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import tmapGoogle from "../../assests/images/2 (City).png";
import Footer from "../subcomponents/footer";
import axios from "axios";
import hicon from "../../assests/Icons/Hcontacticon.png";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export const Contact = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Send Message");
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
    designation: "",
    organization: "",
    attachment: null,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, attachment: file });
  };
  const handleRemoveAttachment = () => {
    setFormData({ ...formData, attachment: null });
    document.getElementById("file-input").value = ""; // Clear file input value
  };
  const handleDesignationChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, designation: value });
  };

  const handleMessageChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, message: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };
  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      phone,
      email,
      message,
      organization,
      designation,
      attachment,
    } = formData;
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.phone ||
      !formData.email ||
      !formData.message ||
      !formData.designation ||
      !formData.organization
      // !formData.attachment
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    const data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("phone", phone);
    data.append("email", email);
    data.append("organization", organization);
    data.append("proposal", message);
    data.append("designation", designation);
    data.append("message", "designation");
    data.append("attachment", attachment);
    // api/submit-contact-form
    // console.log("FormData", data);
    try {
      // Assuming you have an API endpoint like "https://example.com/api/submit-contact-form"

      //         const response = await axios.get(`${apiUrl}event/${id}`);

      // const apiUrl = ;

      const response = await axios.post(`${apiUrl}submit-contact-form`, data);

      // Handle the success case
      // console.log("Success:", response.data);

      // Optionally, you can show a success prompt here
      setIsDisabled(true);
      setButtonText("Sent");

      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setIsDisabled(false);
        setButtonText("Send Message");
      }, 5000);
    } catch (error) {
      window.alert("Form submitted successfully!");
      setIsDisabled(true);
      setButtonText("Sent");

      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setIsDisabled(false);
        setButtonText("Send Message");
      }, 5000);
      // Handle different error cases
      // if (error.response) {

      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   // console.error("Server Error:", error.response.data);
      //   window.alert("Server Error. Please try again later.");
      // } else if (error.request) {
      //   // The request was made but no response was received
      //   // console.error("Request Error:", error.request);
      //   window.alert("No response from the server. Please try again later.");
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   // console.error("General Error:", error.message);
      //   window.alert("An error occurred. Please try again later.");
      // }
    }
  };
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <div>
      <div className="contactusBG">
        <div
          className="relative bg-cover bg-center h-[256px] md:h-[554px] overflow-hidden bg-white contactusBG"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 z-50 "
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading !text-3xl md:!text-5xl font-bold ">
                  Contact Us{" "}
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* background: #F6F6F6;  */}
      <div className="lg:grid lg:grid-cols-12 flex flex-col ">
        <div className="col-span-8 bg-[#F6F6F6] w-full px-4 md:px-12 lg:px-24 xl:px-44 py-8 md:py-20 ">
          <div className="whySIFC mb-4 md:mb-[40px] !text-2xl md:!text-[50px] !text-center !leading-none">
            SIFC Ticketing Hub
            <br />
            <span className="!text-lg md:!text-[31px] !leading-normal">
              Core of Investor Support
            </span>
          </div>
          <div className="flex flex-col justify-center items-center mx-4">
            {/* <div className="grid grid-cols-1 md:grid-cols-2 !w-full gap-4 ">
              <input
                className="border-b md:mt-[24px] !w-full py-3  rounded inputtextcontact "
                placeholder="First Name*"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              ></input>
              <input
                className="border-b md:mt-[24px] !w-full py-3  rounded inputtextcontact "
                placeholder="Last Name*"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              ></input>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 !w-full ">
              <PhoneInput
                className="border-b mt-[24px] !p-0 !w-full py-3  rounded inputtextcontact "
                defaultCountry="pk"
                value={formData.phone}
                onChange={handlePhoneChange}
              />
              <input
                className="border-b md:mt-[24px] !w-full py-3  rounded inputtextcontact "
                placeholder="Email*"
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              ></input>
            </div>
            <input
              className="border-b mt-[24px] !w-full py-3  rounded inputtextcontact "
              placeholder="Company/Organization"
              type="text"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
            ></input>
            <input
              className="border-b !w-full mt-[24px] py-3  rounded inputtextcontact "
              placeholder="Designation"
              type="text"
              name="designation"
              value={formData.designation}
              onChange={handleDesignationChange}
            ></input>
            <label className=" mt-[24px] attachfile w-full cursor-pointer Poppins py-3  h-[50px]">
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                id="file-input"
              />
              <div className="file-input-label">
                {formData.attachment ? (
                  <>
                    <span>{formData.attachment.name}</span>
                    <span
                      className="border-b !w-[750px] py-3  rounded inputtextcontact"
                      onClick={handleRemoveAttachment}
                    >
                      &#10005;
                    </span>
                  </>
                ) : (
                  <span className="flex flex-row gap-3 items-center">
                    {" "}
                    Attachment{" "}
                    <svg
                      width="11"
                      height="13"
                      viewBox="0 0 11 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.84495 2.13417L8.15791 0.476669C7.85212 0.167915 7.4495 0 7.02646 0H2.29163C1.3742 0 0.625 0.796234 0.625 1.77126V11.2287C0.625 12.2037 1.3742 13 2.29163 13H8.70837C9.62577 13 10.375 12.2037 10.375 11.2287V3.43415C10.375 2.94125 10.1813 2.47 9.84495 2.13417ZM3.83592 4.45248C5.2138 2.9881 5.16598 3.03168 5.21714 2.9954C5.29867 2.93582 5.40061 2.90333 5.49745 2.90333H5.50255C5.63507 2.90333 5.76249 2.9629 5.85933 3.06043C6.3905 3.62714 7.21749 4.50947 7.16408 4.45248C7.36283 4.66374 7.36283 5.00499 7.16408 5.21625C6.9653 5.42751 6.64421 5.42751 6.44543 5.21625L6.01222 4.75582V6.66792C6.01222 6.97125 5.78286 7.20958 5.50255 7.20958C5.21714 7.20958 4.99288 6.97125 4.99288 6.66792V4.75582L4.55457 5.22167C4.36385 5.42437 4.03865 5.43713 3.83592 5.22167C3.63714 5.01042 3.63714 4.66374 3.83592 4.45248ZM7.47497 10.0967H3.52503C3.24471 10.0967 3.01536 9.85292 3.01536 9.55501C3.01536 9.25706 3.24471 9.01334 3.52503 9.01334H7.47497C7.75529 9.01334 7.98464 9.25706 7.98464 9.55501C7.98464 9.85292 7.75529 10.0967 7.47497 10.0967Z"
                        fill="#999999"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </label> */}

            <div className="flex flex-col w-full mt-5 mb-12">
              {/* <textarea
                rows="3"
                className="border-b !w-full py-3  rounded inputtextcontact "
                placeholder="Message*"
                name="message"
                value={formData.message}
                onChange={handleMessageChange}
              ></textarea> */}
              <div className="flex md:mb-12 gap-6 justify-center w-full text-[16px]  items-center">
                {/* <button
                  onClick={!isDisabled ? handleSubmit : null}
                  className={`Submitt w-[310px]  py-3 h-[50px] ${
                    isDisabled ? "cursor-progress  !bg-red-700" : ""
                  }`}
                  style={{ pointerEvents: isDisabled ? "none " : "auto" }}
                >
                  {buttonText}
                </button> */}
                <a
                  className="group rounded-full flex items-center gap-2 md:gap-8 pl-1 pr-2 md:pr-12 py-1 text-[14px] md:text-[18px]   hover:text-[#2F7F3D] bg-white border-2 border-[#C2C2C2] hover:border-[#2F7F3D] text-[#C2C2C2] hover:bg-white  "
                  href="https://ticketinghub.sifc.gov.pk/login"
                >
                  <img
                    className="bg-[#C2C2C2] h-8 md:h-12 group-hover:bg-[#2F7F3D] rounded-full p-0.25"
                    src={hicon}
                  />
                  Sign In
                </a>
                or{" "}
                <a
                  className="group rounded-full flex items-center gap-2 md:gap-8 pl-1 pr-2 md:pr-12 py-1 text-[14px] md:text-[18px]   hover:text-[#2F7F3D] bg-white border-2 border-[#C2C2C2] hover:border-[#2F7F3D] text-[#C2C2C2] hover:bg-white  "
                  href="https://ticketinghub.sifc.gov.pk/register"
                >
                  <img
                    className="bg-[#C2C2C2] h-8 md:h-12 group-hover:bg-[#2F7F3D] rounded-full p-0.25"
                    src={hicon}
                  />
                  Sign Up{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="whySIFC  !text-xl md:!text-[28px] !text-center">
            Key Features
          </div>
          <div className="grid grid-cols-4 gap-4 my-8">
            <div class="flex gap-4 col-span-2 text-[15px] border border-[#369E47] rounded-lg  p-3 !text-center w-full ">
              A single-window platform for investor facilitation across all
              sectors and regions
            </div>
            <div class="flex gap-4 col-span-2 text-[15px] border border-[#369E47] rounded-lg  p-3 !text-center w-full ">
              Tailored support with a unique tracking system for seamless
              progress monitoring
            </div>
            <div class="flex gap-4 col-span-2 text-[15px] border border-[#369E47] rounded-lg  p-3 !text-center w-full ">
              Swift and proactive responses within 48 hours by dedicated teams
            </div>
            <div class="flex gap-4 col-span-2 text-[15px] border border-[#369E47] rounded-lg  p-3 !text-center w-full ">
              Transparent engagement ensures stakeholders stay updated at every
              step
            </div>
            <div class="flex gap-4 col-start-2 col-span-2 text-[15px] border border-[#369E47] rounded-lg  p-3 !text-center w-full ">
              Formal closure of facilitation requests with proper investor
              notification
            </div>
          </div>
        </div>
        <div className="bg-[#073332] col-span-4">
          <div className="flex flex-col items-center lg:items-start justify-center py-4 md:py-36 px-4 md:px-20">
            <div className="contactusInforheading !text-[25px] md:!text-[32px] !text-center lg:!text-left">
              Contact Information
            </div>
            {/* <div className="flex flex-col mt-[50px]">
              <div className="contactusInforheading !text-[25px] md:!text-[32px] !text-center lg:!text-left">
                Phone Number
              </div>
              <div className="contactusInforText !text-center lg:!text-left">
                +92 052 325 698 54
              </div>
            </div> */}

            <div className="flex flex-col mt-4 md:mt-[50px]">
              <div className="contactusInforheading !text-[25px] md:!text-[32px] !text-center lg:!text-left">
                Location
              </div>
              <div className="contactusInforText !text-center lg:!text-left">
                SIFC Secretariat, 2nd Floor,<br></br>Prime Minister's Office,
                Constitution Avenue,
                <br></br>Islamabad.
              </div>
            </div>
            <div className="flex flex-col mt-4 md:mt-[50px] items-center lg:items-start justify-center lg:!justify-start">
              <div className="contactusInforheading !text-[25px] md:!text-[32px] !text-center lg:!text-left">
                Support
              </div>
              <a
                href="mailto:invest@sifc.gov.pk"
                className="contactusInforText !text-center lg:!text-left"
              >
                support@ticketinghub.sifc.gov.pk{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 md:py-36 flex flex-col justify-center items-center">
        <div className="container px-4">
          <div className="headingFindUs px-4 !text-[25px] md:!text-[32px] !text-center">
            Find Us on Google Maps
          </div>
          {/* <div className="textFindUs text-justify">
            Unlock unparalleled investment potential in the heart of Cholistan
            with our transformative <br></br>project in corporate farming
            spanning 50,000 acres of culturable wasteland.
          </div> */}
          <div className="mt-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.3561596171394!2d73.09780777457549!3d33.72560527328172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc1c26a5c0a89%3A0xcb0a1563c1e92cf3!2sSpecial%20Investment%20Facilitation%20Council%20(SIFC)!5e0!3m2!1sen!2s!4v1705903668737!5m2!1sen!2s"
              width="100%"
              height="400"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>{" "}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
