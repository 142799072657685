import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import React, { useState, useEffect } from "react";
import axios from "axios";

import SuccessLeadership from "./SuccessSlider";
import "./SuccessStories.css";
import { Link } from "react-router-dom";

import location from "../../assests/images/location.png";
import calender from "../../assests/images/calender.png";
const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function SuccessStories() {
  const [stories, setStories] = useState([]);
  // const imaeglinkservrt = "https://cms.sifc.gov.pk/";
  // "staging.nitb.gov.pk:8620/";
  useEffect(() => {
    const fetchData = async () => {
      try {
        // `${apiUrl}news/${id}`
        const response = await axios.get(`${apiUrl}all-stories`);
        setStories(response?.data);
        console.log(response.data, "event");
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // setLoading(false);
        // console.log("asd");
      }
    };
    fetchData();
  }, []);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div className="bgPictureSucessStories">
        <div
          className="relative bg-cover bg-center h-[256px] md:h-[554px] overflow-hidden bg-white bgPictureSucessStories"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}

          <div className="relative">
            <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading font-bold !text-[30px] md:!text-5xl">
                  Our Success Stories
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="contianer-width">
          <SuccessLeadership />
          <div className="flex md:flex-nowrap flex-wrap gap-8 m-4 md:m-12 mx-auto justify-center">
            {stories?.length > 0 && (
              <>
                <div class="w-max bg-white rounded-lg relative ">
                  <img
                    class="rounded-lg w-[761px] h-[467px] object-cover"
                    src={imaeglinkservrt + stories[1]?.image}
                    alt=""
                  ></img>

                  <div className="-mt-28">
                    <div className=" ml-4 z-20 relative border-l-4 w-max border-[#F08A23] text-white text-base font-medium bg-[#073332BF] py-1 px-3">
                      Success Stories{" "}
                    </div>

                    <div class="flex flex-col ml-4 ">
                      <div className="text-white mt-1 mb-1 font-semibold text-xl text-left z-[999]">
                        <Link to={`/success_stories/details/${stories[1]?.id}`}>
                          {stories[1]?.title}{" "}
                        </Link>
                      </div>

                      <div className="flex w-full">
                        <div className="flex items-center content-center">
                          <img src={location} alt="" />
                          <div className="text-white ml-2 font-semibold text-base text-center z-[999]">
                            Islamabad{" "}
                          </div>
                        </div>
                        <div className="flex items-center ml-6 content-center">
                          <img src={calender} alt="" />
                          <div className="text-white ml-2 font-semibold text-base text-center z-[999]">
                            {/* {stories[1].date} */}

                            {formatDate(stories[1]?.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="absolute -bottom-5 bg-gradient from-transparen"></div>
                  </div>
                </div>
                <div class="w-max bg-white rounded-lg relative ">
                  <img
                    class="rounded-lg w-[761px] h-[467px] object-cover"
                    src={imaeglinkservrt + stories[2].image}
                    alt=""
                  ></img>
                  <div className="-mt-28">
                    <div className=" ml-4 z-20 relative border-l-4 w-max border-[#F08A23] text-white text-base font-medium bg-[#073332BF] py-1 px-3">
                      Success Stories{" "}
                    </div>

                    <div class="flex flex-col ml-4">
                      <div className="text-white mt-1 mb-1 font-semibold text-xl text-left z-[999]">
                        <Link to={`/success_stories/details/${stories[2].id}`}>
                          {" "}
                          {stories[2].title}{" "}
                        </Link>
                      </div>

                      <div className="flex w-full">
                        <div className="flex items-center content-center">
                          <img src={location} alt="" />
                          <div className="text-white ml-2 font-semibold text-base text-center z-[999]">
                            Islamabad{" "}
                          </div>
                        </div>
                        <div className="flex items-center ml-6 content-center">
                          <img src={calender} alt="" />
                          <div className="text-white ml-2 font-semibold text-base text-center z-[999]">
                            {/* {stories[2].date} */}
                            {formatDate(stories[2].date)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="absolute -bottom-5 bg-gradient from-transparen"></div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-wrap flex-row gap-8 m-4 md:m-12 mx-auto justify-center">
            {stories.slice(2).map((story, index) => (
              <div key={index} class="w-[317px]  rounded-lg ">
                <img
                  class="rounded-lg  object-cover w-[331px] h-[320px]"
                  src={imaeglinkservrt + story.image}
                  alt=""
                ></img>
                <div className="-mt-16 ml-4 z-20 relative border-l-4 w-[140px] border-[#F08A23] text-white text-base font-medium bg-[#073332BF] py-1 px-3">
                  Success Stories
                </div>

                <div class="flex flex-col mt-10">
                  <Link to={`/success_stories/details/${story.id}`}>
                    <div className="text-[#26532F] mt-1 mb-1 font-semibold text-xl text-left">
                      {story.title}
                    </div>
                  </Link>
                  <div className="flex w-full">
                    <div className="flex items-center content-center">
                      <img src={location} alt="" />
                      <div className="text-[#26532F] ml-2 font-normal text-base text-center">
                        Islamabad{" "}
                      </div>
                    </div>
                    <div className="flex items-center ml-6 content-center">
                      <img src={calender} alt="" />
                      <div className="text-[#26532F] ml-2 font-normal text-base text-center">
                        {/* {story.date} */}
                        {formatDate(story.date)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
