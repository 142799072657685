import React, { useState, useEffect, useRef } from "react";
import informationTechImg from "../../assests/images/Vector.svg";
import powerImg from "../../assests/images/Vector(1).svg";
import mineralsImgbg from "../../assests/images/powerbw.svg";
import mineralsImg from "../../assests/images/Vector(2).svg";
import industryImag from "../../assests/Icons/fi_10878990.svg";
import industryImagbw from "../../assests/images/IdustryBW.svg";
import MineralBW from "../../assests/images/Mineralbw.png";
import AgricultureImg from "../../assests/images/Frame 7605.svg";
import Agribw from "../../assests/images/Agriculbw.png";
import Powerbg from "../../assests/images/PowerBG.svg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import icon from "../../assests/Icons/icon.png";

function HeroSectionsSector22() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [isDropdownOpenAgri, setDropdownOpenAgri] = useState(false);
  const [isDropdownOpenITT, setDropdownOpenITT] = useState(false);
  const [isDropdownOpenMM, setDropdownOpenMM] = useState(false);
  const [isDropdownOpenEnergy, setDropdownOpenEnergy] = useState(false);
  const [isDropdownOpenIandT, setDropdownOpenIandT] = useState(false);
  const dropdownRef = useRef(null);
  var settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    // speed: 5000,
    // autoplaySpeed: 50,
    // cssEase: "linear",
    initialSlide: 0,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    scrollToDropdown();
  }, [
    isDropdownOpenAgri,
    isDropdownOpenITT,
    isDropdownOpenMM,
    isDropdownOpenEnergy,
    isDropdownOpenIandT,
  ]);

  const scrollToDropdown = () => {
    if (dropdownRef.current) {
      const dropdownPosition = dropdownRef.current.offsetTop;

      const offset = 150; // Adjust this value based on your needs
      const targetPosition = dropdownPosition + offset;
      const currentOffset = window.scrollY || window.pageYOffset;
      if (dropdownRef.current.offsetTop + offset > currentOffset) {
        // Scroll to the target position
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      } else {
      }

      // Optionally, toggle the dropdown visibility
      toggleDropdown();
    }
  };
  const toggleDropdown = () => {
    if (dropdownRef.current) {
      const dropdown = dropdownRef.current;
      dropdown.style.display =
        dropdown.style.display === "block" ? "none" : "block";
    }
  };
  return (
    <>
      <div className="xxl:px-[100px] 2xl:px-0 relative mt-[-50px] lg:max-w-[1440px] flex flex-col w-full mx-auto  justify-center items-center">
        <div className="hidden lg:grid grid-cols-5 lg:px-0 px-8 w-full  ">
          <div
            className={`relative cursor-pointer px-[40px]    !z-10  flex flex-row justify-center py-8 content-center gap-4 items-center border-r border-[#DEDBDB]  border-l border-b ${
              isDropdownOpenAgri ? " dropdowntop222  " : " topdropdown222  "
            }`}
            onMouseEnter={() => setDropdownOpenAgri(true)}
            onMouseLeave={() => setDropdownOpenAgri(false)}
          >
            {/* //   {isDropdownOpenAgri ? (
          //     <img alt="sifc" src={AgricultureImg}></img>
          //   ) : ( */}
            <img alt="sifc" src={Agribw}></img>
            {/* )} */}

            <h2 className="heading234 hover:!text-black">
              Agriculture & Livestock
            </h2>

            {isDropdownOpenAgri && (
              <div
                ref={dropdownRef}
                className="absolute top-full dropdownOpen pb-[10px] px-[90px] z-10     gap-2 border flex flex-col  justify-center items-center  border-r-[#EEEEEE] border-t-0  border-l-[#EEEEEE] rounded-b-xl"
              >
                {/* Add your dropdown content here */}
                <Link to="/agricuture-sector#Potentiol">
                  <p> Potential</p>
                </Link>
                <Link to="/agricuture-sector#opportunities">
                  {" "}
                  <p>Investment Opportunities</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=1&section=0#policy">
                  <p>Policies</p>
                </Link>
                <Link to="/investment_climate?sector=1&section=1#policy">
                  {" "}
                  <p>Incentives</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=1&section=2#policy">
                  {" "}
                  <p>Regulations</p>{" "}
                </Link>
                {/* <Link to="/agricuture-sector#HowtoInvest">
                  {" "}
                  <p>How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Agriculture#faqs">
                  {" "}
                  <p>FAQs</p>
                </Link> */}
              </div>
            )}
          </div>
          <div
            onClick={scrollToDropdown}
            className={`relative cursor-pointer px-[40px]     !z-10   flex flex-row justify-center py-8 content-center gap-4 items-center border-r border-[#DEDBDB]  border-l border-b  ${
              isDropdownOpenITT ? " dropdowntop222  " : " topdropdown222  !z-10"
            }`}
            onMouseEnter={() => setDropdownOpenITT(true)}
            onMouseLeave={() => setDropdownOpenITT(false)}
          >
            {/* {isDropdownOpenITT ? (
              <img alt="sifc" src={informationTechImg}></img>
            ) : ( */}
            <img alt="sifc" src={MineralBW}></img>
            {/* )} */}

            <h2 className="heading234 hover:!text-black">IT & Telecom</h2>

            {isDropdownOpenITT && (
              <div
                ref={dropdownRef}
                className="absolute top-full dropdownOpen pb-[10px] px-[90px] !z-10   gap-2 border flex flex-col  justify-center items-center  border-r-[#EEEEEE] border-t-0  border-l-[#EEEEEE] rounded-b-xl"
              >
                {/* Add your dropdown content here */}
                <Link to="/ITmainPage#Potentiol">
                  <p> Potential</p>
                </Link>
                <Link to="/ITmainPage#opportunities">
                  {" "}
                  <p>Investment Opportunities</p>{" "}
                </Link>

                <Link to="/investment_climate?sector=2&section=0#policy">
                  <p>Policies</p>
                </Link>
                <Link to="/investment_climate?sector=2&section=1#policy">
                  {" "}
                  <p>Incentives</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=2&section=3#policy">
                  {" "}
                  <p>Regulations</p>{" "}
                </Link>
                {/* <Link to="/ITmainPage#HowtoInvest">
                  {" "}
                  <p>How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=ITTelecom#faqs">
                  {" "}
                  <p>FAQs</p>
                </Link> */}
              </div>
            )}
          </div>
          <div
            onClick={scrollToDropdown}
            className={`relative cursor-pointer px-[40px]    !z-10  flex flex-row justify-center py-8 content-center gap-4 items-center border-r border-[#DEDBDB]  border-l border-b  ${
              isDropdownOpenMM ? " dropdowntop222  " : " topdropdown222  !z-10"
            }`}
            onMouseEnter={() => setDropdownOpenMM(true)}
            onMouseLeave={() => setDropdownOpenMM(false)}
          >
            {/* {isDropdownOpenMM ? (
              <img alt="sifc" src={mineralsImg}></img>
            ) : ( */}
            <img alt="sifc" src={mineralsImgbg}></img>
            {/* )} */}

            <h2 className="heading234 hover:!text-black">Mines & Minerals</h2>

            {isDropdownOpenMM && (
              <div
                ref={dropdownRef}
                className="absolute top-full dropdownOpen pb-[10px] px-[90px] !z-10   gap-2 border flex flex-col  justify-center items-center  border-r-[#EEEEEE] border-t-0  border-l-[#EEEEEE] rounded-b-xl"
              >
                {/* Add your dropdown content here */}
                <Link to="/MineralsMainPage#Potentiol">
                  <p> Potential</p>
                </Link>
                <Link to="/MineralsMainPage#opportunities">
                  {" "}
                  <p>Investment Opportunities</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=3&section=0#policy">
                  <p>Policies</p>
                </Link>
                <Link to="/investment_climate?sector=3&section=1#policy">
                  {" "}
                  <p>Incentives</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=3&section=2#policy">
                  {" "}
                  <p>Regulations</p>{" "}
                </Link>
                {/* <Link to="/ITTelecom#HowtoInvest">
                  {" "}
                  <p>How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Mines#faqs">
                  {" "}
                  <p>FAQs</p>
                </Link> */}
              </div>
            )}
          </div>
          <div
            onClick={scrollToDropdown}
            className={`relative cursor-pointer px-[40px]  !z-10   flex flex-row justify-center py-8 content-center gap-4 items-center  border-r border-[#DEDBDB]  border-l border-b ${
              isDropdownOpenEnergy
                ? " dropdowntop222  "
                : " topdropdown222  !z-10"
            }`}
            onMouseEnter={() => setDropdownOpenEnergy(true)}
            onMouseLeave={() => setDropdownOpenEnergy(false)}
          >
            {/* {isDropdownOpenEnergy ? (
              <img alt="sifc" src={powerImg}></img>
            ) : ( */}
            <img alt="sifc" src={Powerbg}></img>
            {/* )} */}

            <h2 className="heading234 hover:!text-black">
              Energy<br></br> (Petroleum & Power)
            </h2>

            {isDropdownOpenEnergy && (
              <div
                ref={dropdownRef}
                className="absolute top-full dropdownOpen pb-[10px] px-[90px] !z-10  gap-2 border flex flex-col  justify-center items-center  border-r-[#EEEEEE] border-t-0  border-l-[#EEEEEE] rounded-b-xl"
              >
                {/* Add your dropdown content here */}
                <Link to="/EnergyMainPage#Potentiol">
                  <p> Potential</p>
                </Link>
                <Link to="/EnergyMainPage#opportunities">
                  {" "}
                  <p>Investment Opportunities</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=4&section=0#policy">
                  <p>Policies</p>
                </Link>
                <Link to="/investment_climate?sector=4&section=1#policy">
                  {" "}
                  <p>Incentives</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=4&section=2#policy">
                  {" "}
                  <p>Regulations</p>{" "}
                </Link>
                {/* <Link to="/EnergyMainPage#HowtoInvest">
                  {" "}
                  <p>How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Energy#faqs">
                  {" "}
                  <p>FAQs</p>
                </Link> */}
              </div>
            )}
          </div>
          <div
            onClick={scrollToDropdown}
            className={`relative cursor-pointer px-[40px]     flex flex-row justify-center py-8 content-center gap-4 items-center border-r border-[#DEDBDB]  border-l border-b${
              isDropdownOpenIandT
                ? " dropdowntop222  "
                : " topdropdown222  !z-10"
            }`}
            onMouseEnter={() => setDropdownOpenIandT(true)}
            onMouseLeave={() => setDropdownOpenIandT(false)}
          >
            {/* {isDropdownOpenIandT ? (
              <img alt="sifc" src={industryImag}></img>
            ) : ( */}
            <img alt="sifc" src={industryImagbw}></img>
            {/* )} */}

            <h2 className="heading234 hover:!text-black">
              Industry, Tourism & Privatization
            </h2>

            {isDropdownOpenIandT && (
              <div
                ref={dropdownRef}
                className="absolute top-full dropdownOpen pb-[10px] px-[90px] !z-10   gap-2 border flex flex-col  justify-center items-center  border-r-[#EEEEEE] border-t-0  border-l-[#EEEEEE] rounded-b-xl"
              >
                {/* Add your dropdown content here */}
                <Link to="/IndustryMainPage#Potentiol">
                  <p> Potential</p>
                </Link>
                <Link to="/IndustryMainPage#opportunities">
                  {" "}
                  <p>Investment Opportunities</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=5&section=0#policy">
                  <p>Policies</p>
                </Link>
                <Link to="/investment_climate?sector=5&section=1#policy">
                  {" "}
                  <p>Incentives</p>{" "}
                </Link>
                <Link to="/investment_climate?sector=5&section=2#policy">
                  {" "}
                  <p>Regulations</p>{" "}
                </Link>
                {/* <Link to="/IndustryMainPage#HowtoInvest">
                  {" "}
                  <p>How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Industry#faqs">
                  {" "}
                  <p>FAQs</p>
                </Link> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="block lg:hidden mx-8 rounded-lg">
          <Slider {...settings1}>
            <a
              className={`!rounded-lg relative cursor-pointer px-[10px] flex flex-col justify-center py-[15px] gap-1 items-center  border-[#DEDBDB] !border-b-0 ${
                isDropdownOpenAgri ? " dropdowntop  " : " topdropdown  z-10"
              }`}
              onMouseEnter={() => setDropdownOpenAgri(true)}
              onMouseLeave={() => setDropdownOpenAgri(false)}
            >
              <div className="flex justify-center !flex-row gap-2">
                <h2
                  className={` ${
                    isDropdownOpenAgri ? " heading22222  " : "heading222 "
                  }`}
                  // href="/AgricutureSectors"
                >
                  Agriculture & Livestock
                </h2>
                <img src={icon} alt="" />
              </div>
              {isDropdownOpenAgri && (
                <div className="relative top-full dropdownOpen w-full z-10 !border-0  !shadow-none  gap-2  flex flex-col  justify-center items-center rounded-b-xl ">
                  {/* Add your dropdown content here */}
                  <Link to="/agricuture-sector#Potentiol">
                    <p className="!text-sm"> Potential</p>
                  </Link>
                  <Link to="/agricuture-sector#opportunities">
                    {" "}
                    <p className="!text-sm">Investment Opportunities</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=1&section=0#policy">
                    <p className="!text-sm">Policies</p>
                  </Link>
                  <Link to="/investment_climate?sector=1&section=1#policy">
                    {" "}
                    <p className="!text-sm">Incentives</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=1&section=2#policy">
                    {" "}
                    <p className="!text-sm pb-1">Regulations</p>{" "}
                  </Link>
                  {/* <Link to="/AgricutureSectors#HowtoInvest">
                  {" "}
                  <p className="!text-sm">How to Invest</p>{" "}
                 </Link>
                 <Link to="/FAQs?sector=Agriculture#faqs">
                  {" "}
                  <p className="!text-sm">FAQs</p>
                 </Link> */}
                </div>
              )}
            </a>
            <a
              className={`!rounded-lg relative cursor-pointer px-[10px]  flex flex-col justify-center py-[15px] gap-1 items-center  border-[#DEDBDB]  border-l-0 !border-b-0 ${
                isDropdownOpenITT
                  ? " dropdowntop !text-black "
                  : " topdropdown !text-black  z-10"
              }`}
              onMouseEnter={() => setDropdownOpenITT(true)}
              onMouseLeave={() => setDropdownOpenITT(false)}
              // href="/ITmainPage"
            >
              <div className="flex justify-center !flex-row gap-2">
                <h2
                  className={` ${
                    isDropdownOpenITT ? " heading22222  " : "heading222 "
                  }`}
                >
                  IT & Telecom
                </h2>{" "}
                <img src={icon} alt="" />
              </div>
              {isDropdownOpenITT && (
                <div className="relative top-full dropdownOpen w-full z-10 !border-0  !shadow-none  gap-2  flex flex-col  justify-center items-center rounded-b-xl ">
                  {" "}
                  {/* Add your dropdown content here */}
                  <Link to="/ITmainPage#Potentiol">
                    <p className="!text-sm"> Potential</p>
                  </Link>
                  <Link to="/ITmainPage#opportunities">
                    {" "}
                    <p className="!text-sm">Investment Opportunities</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=2&section=0#policy">
                    <p className="!text-sm">Policies</p>
                  </Link>
                  <Link to="/investment_climate?sector=2&section=1#policy">
                    {" "}
                    <p className="!text-sm">Incentives</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=2&section=3#policy">
                    {" "}
                    <p className="!text-sm pb-1">Regulations</p>{" "}
                  </Link>
                  {/* <Link to="/ITmainPage#HowtoInvest">
                  {" "}
                  <p className="!text-sm">How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=ITTelecom#faqs">
                  {" "}
                  <p className="!text-sm">FAQs</p>
                </Link> */}
                </div>
              )}
            </a>
            <a
              className={`!rounded-lg relative cursor-pointer px-[10px]  flex flex-col justify-center py-[15px] gap-1 items-center  border-[#DEDBDB]  border-l-0 !border-b-0 ${
                isDropdownOpenMM ? " dropdowntop  " : " topdropdown  z-10"
              }`}
              onMouseEnter={() => setDropdownOpenMM(true)}
              onMouseLeave={() => setDropdownOpenMM(false)}
              // href="/MineralsMainPage"
            >
              <div className="flex justify-center !flex-row gap-2">
                <h2
                  className={` ${
                    isDropdownOpenMM ? " heading22222  " : "heading222 "
                  }`}
                >
                  Mines & Minerals
                </h2>{" "}
                <img src={icon} alt="" />
              </div>
              {isDropdownOpenMM && (
                <div className="relative top-full dropdownOpen w-full z-10 !border-0  !shadow-none  gap-2  flex flex-col  justify-center items-center rounded-b-xl ">
                  {" "}
                  {/* Add your dropdown content here */}
                  <Link to="/MineralsMainPage#Potentiol">
                    <p className="!text-sm"> Potential</p>
                  </Link>
                  <Link to="/MineralsMainPage#opportunities">
                    {" "}
                    <p className="!text-sm">Investment Opportunities</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=3&section=0#policy">
                    <p className="!text-sm">Policies</p>
                  </Link>
                  <Link to="/investment_climate?sector=3&section=1#policy">
                    {" "}
                    <p className="!text-sm">Incentives</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=3&section=2#policy">
                    {" "}
                    <p className="!text-sm pb-1">Regulations</p>{" "}
                  </Link>
                  {/* <Link to="/ITTelecom#HowtoInvest">
                  {" "}
                  <p className="!text-sm">How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Mines#faqs">
                  {" "}
                  <p className="!text-sm">FAQs</p>
                </Link> */}
                </div>
              )}
            </a>
            <a
              className={`!rounded-lg relative cursor-pointer px-[10px]    flex flex-col justify-center py-[15px] gap-1 items-center  border-[#DEDBDB]  border-l-0 !border-b-0 ${
                isDropdownOpenEnergy ? " dropdowntop  " : " topdropdown  z-10"
              }`}
              onMouseEnter={() => setDropdownOpenEnergy(true)}
              onMouseLeave={() => setDropdownOpenEnergy(false)}
              // href="/EnergyMainPage"
            >
              <div className="flex justify-center !flex-row gap-2">
                <h2
                  className={` ${
                    isDropdownOpenEnergy ? " heading22222  " : "heading222 "
                  }`}
                >
                  Energy (Petroleum & Power)
                </h2>{" "}
                <img src={icon} alt="" />
              </div>
              {isDropdownOpenEnergy && (
                <div className="relative top-full dropdownOpen w-full z-10 !border-0  !shadow-none  gap-2  flex flex-col  justify-center items-center rounded-b-xl ">
                  {" "}
                  {/* Add your dropdown content here */}
                  <Link to="/EnergyMainPage#Potentiol">
                    <p className="!text-sm"> Potential</p>
                  </Link>
                  <Link to="/EnergyMainPage#opportunities">
                    {" "}
                    <p className="!text-sm">Investment Opportunities</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=4&section=0#policy">
                    <p className="!text-sm">Policies</p>
                  </Link>
                  <Link to="/investment_climate?sector=4&section=1#policy">
                    {" "}
                    <p className="!text-sm">Incentives</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=4&section=2#policy">
                    {" "}
                    <p className="!text-sm pb-1">Regulations</p>{" "}
                  </Link>
                  {/* <Link to="/EnergyMainPage#HowtoInvest">
                  {" "}
                  <p className="!text-sm">How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Energy#faqs">
                  {" "}
                  <p className="!text-sm">FAQs</p>
                </Link> */}
                </div>
              )}
            </a>
            <a
              className={`!rounded-lg relative cursor-pointer px-[10px] flex flex-col justify-center py-[15px] gap-1 items-center border-[#DEDBDB]  border-l-0 !border-b-0 ${
                isDropdownOpenIandT ? " dropdowntop  " : " topdropdown  z-10"
              }`}
              onMouseEnter={() => setDropdownOpenIandT(true)}
              onMouseLeave={() => setDropdownOpenIandT(false)}
              // href="/IndustryMainPage"
            >
              <div className="flex justify-center !flex-row gap-2">
                <h2
                  className={` ${
                    isDropdownOpenIandT ? " heading22222  " : "heading222 "
                  }`}
                >
                  Industry, Tourism & Privatisation
                </h2>{" "}
                <img src={icon} alt="" />
              </div>
              {isDropdownOpenIandT && (
                <div className="relative top-full dropdownOpen w-full z-10 !border-0  !shadow-none  gap-2  flex flex-col  justify-center items-center rounded-b-xl ">
                  {" "}
                  {/* Add your dropdown content here */}
                  <Link to="/IndustryMainPage#Potentiol">
                    <p className="!text-sm"> Potential</p>
                  </Link>
                  <Link to="/IndustryMainPage#opportunities">
                    {" "}
                    <p className="!text-sm">Investment Opportunities</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=5&section=0#policy">
                    <p className="!text-sm">Policies</p>
                  </Link>
                  <Link to="/investment_climate?sector=5&section=1#policy">
                    {" "}
                    <p className="!text-sm">Incentives</p>{" "}
                  </Link>
                  <Link to="/investment_climate?sector=5&section=2#policy">
                    {" "}
                    <p className="!text-sm pb-1">Regulations</p>{" "}
                  </Link>
                  {/* <Link to="/IndustryMainPage#HowtoInvest">
                  {" "}
                  <p className="!text-sm">How to Invest</p>{" "}
                </Link>
                <Link to="/FAQs?sector=Industry#faqs">
                  {" "}
                  <p className="!text-sm">FAQs</p>
                </Link> */}
                </div>
              )}
            </a>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default HeroSectionsSector22;
